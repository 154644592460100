
import React from 'react'
import { connect } from 'react-redux'
import { Layout,Avatar, Menu, Breadcrumb,Button,Row, Col, Divider,Card,Dropdown,Switch,
    message, Tooltip,InputNumber,Table,Popover,Modal,Image,Form,Select,Spin} from 'antd';
import { Drawer, List,Alert,notification,Input,Affix } from 'antd';

import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LaptopOutlined,
  NotificationOutlined,
  SettingOutlined,
  AuditOutlined,
  IdcardOutlined,
  BarsOutlined,
  ShopOutlined,
  DollarOutlined,
  PicLeftOutlined,
  LogoutOutlined,
  EditOutlined, 
  EllipsisOutlined,
  HomeOutlined,
  SnippetsOutlined,
  KeyOutlined,
  FilePdfFilled,
  RightCircleFilled,
  DollarCircleOutlined,
  AccountBookOutlined,
  FilePptOutlined,
  PayCircleOutlined,
  BarChartOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  BankFilled,
  AndroidOutlined,
  DeploymentUnitOutlined,
  GroupOutlined,
  AccountBookFilled,
  CalculatorFilled,
  FileFilled,
  MessageFilled,
  LoadingOutlined,
  FundViewOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { Badge, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem } from 'reactstrap';
import UIfx from 'uifx';

import { NavLink } from 'react-router-dom'
import { PageHeader, Descriptions } from 'antd';
import { Link,withRouter} from 'react-router-dom';
import * as actions from '../../store/actions/auth'
import axios from 'axios'
import UserAccountUpdateForm from '../components/UserAccountUpdateForm'
import WrappedPasswordResetForm from './PasswordResetForm'
import * as serverconfig from '../serverconn'
import * as serversocketconfig from '../serversocketconfig'

import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import ReconnectingWebSocket from 'reconnecting-websocket';
import tickAudio from '../../assets/sounds/that-was-quick.mp3';
import * as primary_color from '../primarycolor'

var CryptoJS = require("crypto-js");

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const style = { background: '#0092ff', padding: '8px 0' };
const { Meta } = Card;
const dateFormat = 'DD/MM/YYYY';
const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const tick = new UIfx(tickAudio,  {
  volume: 1.0, // number between 0.0 ~ 1.0
  throttleMs: 100
});

const pStyle = {
  fontSize: 16,
  lineHeight: '24px',
  display: 'block',
  marginBottom: 16,
};



const DescriptionItem = ({ title, content }) => (
  <div
    className="site-description-item-profile-wrapper"
    style={{
      fontSize: 14,
      lineHeight: '22px',
      marginBottom: 7,
    }}
  >
    <p
      className="site-description-item-profile-p"
      style={{
        marginRight: 8,
        display: 'inline-block',
      }}
    >
      {title}:
    </p>
    {content}
  </div>
);

var bizuserid= ''
var ngoid= ''
var token= ''


class SaccoLayout extends React.Component{

  state = {
    collapsed: false,
    theme: 'dark',
    current: '1',
    titlecolor: '#fff',
    titlevisibility:"visible",
    userprofile:{},
    companyprofile:{},
    isToggle: null,
    visible: false,
    resetmodalvisible:false,
    updateformvisible:'none',
    userrights:{},
    date_today:moment().format(dateFormat).toString(),
    dashboardlocked:false,
    userbalancemodalvisible:false,
    users:[],
    userto:'',
    transferamount:0,
    datarequested:false,
    transfermode:'',
    safemanagertransfermode:'',
    pendingtransfers:[],
    numberofnotifications:0,
    notificationinfo:'',
    balanceacceptancemodalvisible:false,
    selectedtransferobject:{},
    acceptancestatus:'',
    shownotificationmodal:false,
    notifications:[],

    otp_modal_visible:false,
    otp_code:'',
    otp_sent:false

  };

  // show the profile modal
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  // update user profile
  showUpdateForm=()=>{
    this.state.updateformvisible=="none"?
    this.setState({
      updateformvisible: "block",
    })
    :
    this.setState({
      updateformvisible: "none",
    })    
  }


  /////////////////////////////////////////////
  //PASSWORD RESET MODAL METHODS BEGIN HERE

  showpasswordresetModal = () => {
    this.setState({
      resetmodalvisible: true,
    });
  };

  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ resetmodalvisible: false });

  };


  //show user balance modal
  showuserbalancemodalvisible=()=>{
    this.setState({ userbalancemodalvisible: true });
  }

  hideuserbalancemodalvisible=()=>{
    this.setState({ userbalancemodalvisible: false });
  }

  //show hide balance acceptance modal
  openbalanceacceptancemodalmodal=(record)=>{
    this.setState({ acceptancestatus: ''})
    //console.log(record)
    this.setState({balanceacceptancemodalvisible: true });
    this.setState({selectedtransferobject:record})

  }

  closebalanceacceptancemodalmodal=()=>{
    this.setState({balanceacceptancemodalvisible: false });

  }


//component did mount
  componentDidMount(){
  
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        bizuserid= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    //component mount method
    this.componentmountmethod();
    
  }



//component mount method
componentmountmethod=()=>{
  
  //get the user profile here
  axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}`)
  .then(res => {
      this.setState({
        userprofile:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/companyprofile/${1}`)
  .then(res => {
      this.setState({
        companyprofile:res.data
      })

  })


  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
      this.setState({
        userrights:res.data[0]
      })
  })


  this.setState({datarequested:false})


}


  //method for changing color theme 
  changeTheme = value => {
    this.setState({
      theme: value ? 'dark' : 'light',
    });

    this.setState({
      titlecolor: value ? '#fff' : '#A9A9A9',
    });
    

  };

  //method for collupsing of the menu
  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
    this.setState({titlevisibility: collapsed ? "hidden" :"visible"});
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });

    this.setState({titlevisibility:"hidden"});

  };


  handleusertoChange= (e) => this.setState({ userto: e});
  handletransferamountChange= (e) => this.setState({ transferamount: e});
  handletransfermodeChange= (e) => this.setState({ transfermode: e});
  handlesafemanagertransfermodeChange= (e) => this.setState({ safemanagertransfermode: e});
  handleacceptancestatusChange= (e) => this.setState({ acceptancestatus: e});





   render(){

    return (
      <div>
        {
         this.props.isAuthenticated  && this.state.otp_modal_visible===false?
         <Layout style={{ minHeight: '100vh' }} >

             <Sider
             collapsedWidth="0"
             collapsed={this.state.collapsed}
             onCollapse={this.onCollapse} 
             theme={this.state.theme}
             breakpoint="lg"
             onBreakpoint={broken => {
               console.log(broken);
             }}
             zeroWidthTriggerStyle={{display:'none'}}
             style={{width:400}}
             >
               <br></br>
              <div className="logo" style={{ paddingLeft: 30}} mode="inline">
              <Row>
   
                <Col span={6}>
          {/*   <Avatar size={40} icon={<UserOutlined />} alt="Logo" src={this.state.companyprofile.company_logo} />
           */}               
                <Image
                 width={50}
                 height={50}
                 src={ 
                   this.state.companyprofile.company_logo
                }   
                 preview={false} 
                 
                 />
                <h6 style={{display:'flex',color:'#fff',margin:3}}>{this.state.companyprofile.company_name}</h6> 
                </Col>
       {/*             <Col span={18}><NavLink to='/'><h5 style={{color:this.state.titlecolor,paddingTop: 10,visibility:this.state.collapsed?"hidden":"visible" }}>{this.state.companyprofile.company_name}</h5></NavLink></Col>
         */}  
              
              </Row>
              <br/>
              </div>
   
              <Menu 
              theme={this.state.theme}
              onClick={this.handleClick}
              defaultSelectedKeys={['1']} 
              mode='vertical'
              
              >
   
               <SubMenu
                  key="sub1"
                  title={
                    <span>
                      <HomeOutlined />
                      <span>Home</span>
                    </span>
                  }
                >
               <Menu.Item key="0" >
                 <RightCircleFilled />
                 <NavLink to='/'><span>Home</span></NavLink>
   
               </Menu.Item>
               </SubMenu>


                  {
                    this.state.userrights.can_view_teams?
                    <SubMenu
                    key="sub22"
                    title={
                      <span>
                        <GroupOutlined />
                        <span>Teams</span>
                        </span>
                      }
                    >
                    <Menu.Item key="1" >
                    <RightCircleFilled />
                    <NavLink to='/teams/'><span>Teams</span></NavLink>
                    </Menu.Item>
                    </SubMenu>
                    :
                    null
                  }

                

              {
                this.state.userrights.can_view_purchase?
               <SubMenu
                  key="sub2"
                  title={
                    <span>
                      <UserOutlined />
                      <span>Purchases</span>
                    </span>
                  }
                >
                  <Menu.Item key="001" >
                  <RightCircleFilled />
                  <NavLink to='/purchases/'><span>Purchases & Bonus Mgt</span></NavLink>
                  </Menu.Item>
               </SubMenu>
                  :
                  null
                }
                  
              
              {
                this.state.userprofile.managerial_account===true?
                <SubMenu
                key="sub4"
                title={
                  <span>
                    <SettingOutlined />
                    <span>Settings</span>
                    </span>
                  }
                >
                 <Menu.Item key="11100117" >
                  <RightCircleFilled />
                  <NavLink to='/companyprofile/'><span>Smile Business profile</span></NavLink>
                </Menu.Item>

                <Menu.Item key="1117" >
                  <RightCircleFilled />
                  <NavLink to='/userroles/'><span>User roles</span></NavLink>
                </Menu.Item>
  
                <Menu.Item key="111227" >
                  <RightCircleFilled />
                  <NavLink to='/useraccounts/'><span>System Users</span></NavLink>
                </Menu.Item>

                <Menu.Item key="637383" >
                  <RightCircleFilled />
                  <NavLink to='/stations/'><span>Stations</span></NavLink>
                </Menu.Item>

                <Menu.Item key="63731183" >
                  <RightCircleFilled />
                  <NavLink to='/distributors/'><span>Distributors</span></NavLink>
                </Menu.Item>

                <Menu.Item key="6373111183" >
                  <RightCircleFilled />
                  <NavLink to='/productunits/'><span>Product units</span></NavLink>
                </Menu.Item>

                <Menu.Item key="637311100183" >
                  <RightCircleFilled />
                  <NavLink to='/products/'><span>Products</span></NavLink>
                </Menu.Item>

                <Menu.Item key="22233" >
                  <RightCircleFilled />
                 <NavLink to='/companyaccounts/'><span>SBN accounts </span></NavLink>
                </Menu.Item> 


                </SubMenu>
                :
                null
              }



            {
                this.state.userprofile.managerial_account===true?
                <SubMenu
                key="sub5"
                title={
                  <span>
                    <FilePdfFilled />
                    <span>Financial Reports</span>
                    </span>
                  }
                >


                  <Menu.Item key="23" >
                  <NavLink to='/tillsheet/'><span>Journal</span></NavLink>
                  </Menu.Item>                  
                
                
                  <Menu.Item key="221123" >
                  <NavLink to='/ledgerreport/'><span>Ledger</span></NavLink>
                  </Menu.Item>                  
                

                  <Menu.Item key="22123" >
                  <NavLink to='/trialbalance/'><span>Trial Balance</span></NavLink>
                  </Menu.Item>                  
               

                </SubMenu>
                :
                null
              }
                 
             
              </Menu>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
   { /*          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <br></br>
                 <Switch
                    checked={this.state.theme === 'dark'}
                    onChange={this.changeTheme}
                    checkedChildren="Dark"
                    unCheckedChildren="Light"
                  />
              <br></br>
                 </div> */}
   
            </Sider>
           
         <Layout className="site-layout">
   
            <PageHeader
              style={{backgroundColor: "white"}}
              ghost={true}
              tags={React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {className: 'trigger',
              onClick: this.toggle,
            })}
              extra={[
                <Badge  onClick={()=>{
                  this.setState({shownotificationmodal:true})
              
                }}  pill color="danger">{this.state.numberofnotifications}</Badge>,
                <Avatar size={30} icon={<UserOutlined />} alt="User Pic" src={   
                  //serverconfig.backendserverurl+`/customqueries/getfile/${Number(this.state.userprofile.id)}/${'user_account'}`
                  this.state.userprofile.profile_pic
                
                } />,
                <Dropdown.Button overlay={(    <Menu >
                  <Menu.Item key="1" onClick={this.props.logout}>
                    <LogoutOutlined />
                    Log out
                  </Menu.Item>
                  <Menu.Item key="2" onClick={this.showDrawer}>
                    <UserOutlined />
                    View Profile
                  </Menu.Item>
                  <Menu.Item key="3" onClick={this.showpasswordresetModal}>
                    <KeyOutlined />
                    Change Password
                  </Menu.Item>
                 
            
                </Menu>)}>
                Hi, {this.state.userprofile.username}
                </Dropdown.Button>
              ]}
              >
          </PageHeader>
        


           <Content style={{ margin: '0 16px' }}>
             <br></br>
             {
               this.state.notificationinfo===""?
               null
               :
               <NavLink >
                <Alert showIcon message={this.state.notificationinfo} type="info" onClick={()=>{this.showuserbalancemodalvisible()}} />
               </NavLink>
             }

             <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
             <br></br>
             <div style={{display:'flex',flexDirection:'row'}}>


             
             {
              this.props.location.pathname==='/'?
              null:
              <Affix>

              <Tooltip title="Go to home">
              <HomeOutlined style={{display:'flex',fontSize: 30,marginRight:30}} onClick={()=>{
                this.props.history.push('/')      
                }}/>
              </Tooltip>
              </Affix>
               }


                {
                  this.props.location.pathname==='/'?
                  null:
                  <Affix>
                  <Tooltip title="Go back">
                  <ArrowLeftOutlined style={{display:'flex',fontSize: 30,marginRight:5}} onClick={()=>{
                    this.props.history.goBack()      
                    }}/>
                  </Tooltip>
                  </Affix>
                }
                </div>

                <br></br>

             
             {this.props.children}
             </div>
           </Content>
           <Footer style={{ textAlign: 'center' }}>System Design © 2023 Created by PessaMasters Ltd</Footer>
         </Layout>
         </Layout>
         :
         <Layout style={{ minHeight: '100vh' }}>
         <Content className="site-layout" style={{ padding: '0 50px', marginTop: 64 }}>
           <div className="site-layout-background" style={{padding: 24, minHeight: 380,display: 'flex',  justifyContent:'center', alignItems:'center' }}>
             {/*this.props.children*/}
           </div>
         </Content>
         <Footer style={{ textAlign: 'center' }}>System Design © 2023 Created by PessaMasters Ltd</Footer>
         </Layout>
        }

        <Drawer
          title="Account Profile"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Avatar
              size={100}
              icon={<UserOutlined />}
              alt="client logo"
              src={
                this.state.userprofile.profile_pic
                //serverconfig.backendserverurl+`/customqueries/getfile/${Number(this.state.userprofile.id)}/${'user_account'}`
              }
              />
          </p>
          <Row>
            <Col span={12}>
              <DescriptionItem title="User Name" content={this.state.userprofile.username} />
            </Col>
            <Col span={12}>
              <DescriptionItem title="Email" content={this.state.userprofile.email} />
            </Col>
          </Row>         
          <p style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>      
             <Button type="primary" onClick={this.showUpdateForm}>
               {
                 this.state.updateformvisible=="none"?
                 "Open Update Form"
                 :
                 "Close Update Form"
               }
               
               </Button>
          </p>
          
          <div style={{display:this.state.updateformvisible}}>
          <UserAccountUpdateForm accountID={this.state.userprofile.id} />       

          </div>

        </Drawer>


        <Drawer
          title="Password Change Form"
          width={520}
          placement="right"
          closable={false}
          onClose={this.onresetdrawerClose}
          visible={this.state.resetmodalvisible}
        >
            <WrappedPasswordResetForm />

        </Drawer>

     
         <Modal            
          visible={this.state.shownotificationmodal}
          title="Notifications"
          onCancel={()=>{
            this.setState({shownotificationmodal:false})

            //mark them as read
            this.state.notifications.map((item)=>{
              let form_data = new FormData();
              form_data.append('read', true);

              axios.put(serverconfig.backendserverurl+`/api/ngonotification/${item.id}/`, form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
              })
              .then(res => {
                console.log(res.data)
              })

            })

          }}
      
          footer={[
            <Button key="back" onClick={()=>{
              this.setState({shownotificationmodal:false})

              console.log(this.state.notifications)
              //mark them as read
              this.state.notifications.map((item)=>{
                console.log(item.id)
                let form_data = new FormData();
                form_data.append('read', true);

                axios.put(serverconfig.backendserverurl+`/api/ngonotification/${Number(item.id)}`, form_data, {
                  headers: {
                    'content-type': 'multipart/form-data'
                  }
                })
                .then(res => {
                  console.log(res.data)
                })

              }) 

            }}>
              Cancel
            </Button>
            ]}
            >

          {this.state.notifications.map(
            (note)=>{
              return(<Alert closable style={{margin:6}} showIcon message={note.title} description={note.message} type="success" />);
              
            }

          )}

        </Modal>  
        

    </div>
  
  );

  }
}


const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(
      actions.logout(),
      )
  }
}


export default withRouter(connect(null,mapDispatchToProps)(SaccoLayout)) ;

import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Select,Form,Collapse,Avatar,Tooltip,Popconfirm,message,Popover,Spin,Modal,Descriptions,DatePicker } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { DeleteOutlined,DownloadOutlined } from '@ant-design/icons';
import { SearchOutlined,EditOutlined,WalletFilled,PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import PurchaseReciptView from './PurchaseReceiptView'
import {  Slider,Statistic,Tabs } from 'antd';
import TeamJournalsList from './TeamJournalsListView'
import StationJournalsList from './StationJournalsListView'
import TeamPaymentList from './TeamPaymentListView'

import ReactExport from "@ibrahimrahmani/react-export-excel";
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';


var CryptoJS = require("crypto-js");
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;

const { Panel } = Collapse;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { Search } = Input;
const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var bizuserid= ''
var token= ''


class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

  render() {
    return (
      <div style={{padding:20}}>

        <style>
          {`@media print {
            @page { size: landscape; }
          }`}
          </style>
<Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.company_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
               
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
              
                </Col>
     </Row>


           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>RECEIPT TRACKER FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>

           <reactstrp.Table bordered>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Team name</th>
                        <th>Team contact</th>

                        <th>Station name</th>
                        <th>Distributor</th>
                        <th>Distributor receipt</th>
                        <th>Total amount</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.props.purchase_receipts.map(
                      (item)=>(
                        <tr>
                        <td>{item.date}</td>
                        <td>{item.team_name}</td>
                        <td>{item.tel_contact}</td>

                        <td>{item.station_name}</td>
                        <td>{item.distributor_name}</td>
                        <td>{item.distributer_receiptno}</td>

                        <td>{<CurrencyFormat value={item.totalamount} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>
                      ))}
                      <tr>
                      <td style={{fontWeight:'bolder'}}>Total transctions {this.props.purchase_receipts.length}</td>
                      </tr>
                  </tbody>
            </reactstrp.Table> 

      </div>
    );
  }
}



class PurchaseList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    purchase_receipts: [],
    viewpaymentmodal:false,
    receipttotalpayment:0,
    receiptdetails:{},
    userprofile:{},


    dateone:moment().format(dateFormat).toString(),
    datetwo:moment().format(dateFormat).toString(),
    station:'',
    stations:[],
    dataloading:false,
    purchase_receipts_placeholders: [],

    team:'',
    teams:[],
  
    distributer:'',
    distributers:[],
    search_type:'',
    userrights:{},

  };

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }


    axios.get(serverconfig.backendserverurl+`/api/purchase_receipts/`)
    .then(res => {
        this.setState({
          purchase_receipts:res.data
        })
        this.setState({
          purchase_receipts_placeholders:res.data
        })

        
    })

    
    //get the user profile here
    axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}`,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/stations/`)
    .then(res => {
        this.setState({
          stations:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/distributers/`)
    .then(res => {
        this.setState({
          distributers:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/teams/`)
    .then(res => {
        this.setState({
          teams:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })

}




  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  render() {

      var columns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Journal no',
          dataIndex: 'journal_no',
          key: 'id',
          ...this.getColumnSearchProps('journal_no'),
        },
        {
          title: 'Team',
          dataIndex: 'team_name',
          key: 'id',
          ...this.getColumnSearchProps('team_name'),
        }, 
        {
          title: 'Team contact',
          dataIndex: 'tel_contact',
          key: 'id',
          ...this.getColumnSearchProps('tel_contact'),
        }, 

        {
          title: 'Station',
          dataIndex: 'station_name',
          key: 'id',
          ...this.getColumnSearchProps('station_name'),
        },
        {
          title: 'Distributor',
          dataIndex: 'distributor_name',
          key: 'id',
          ...this.getColumnSearchProps('distributor_name'),
        },

        {
          title: 'Distributor receipt no',
          dataIndex: 'distributer_receiptno',
          key: 'id',
          ...this.getColumnSearchProps('distributer_receiptno'),
        },        

        {
          title: 'Total amount',
          dataIndex: 'totalamount',
          key: 'id',
          render:text=><h4 style={{color:'green'}}><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  /></h4>  
        },
        {
          title: 'Total commission',
          dataIndex: 'total_commission',
          key: 'id',
          render:text=><h4 style={{color:'green'}}><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  /></h4>  
        },
        {
          title: 'Total commission out (team)',
          dataIndex: 'total_commission_out_team',
          key: 'id',
          render:text=><h4 style={{color:'green'}}><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  /></h4>  
        },
        {
          title: 'Total commission out (Upline)',
          dataIndex: 'total_commission_out_upline',
          key: 'id',
          render:text=><h4 style={{color:'green'}}><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  /></h4>  
        },
        {
          title: 'Total dividend',
          dataIndex: 'dividend_total',
          key: 'id',
          render:text=><h4 style={{color:'green'}}><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  /></h4>  
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text => 
          <p>
          <Popover content={<p>Click here to edit,print,view sale details</p>} title="Edit Sale">
          <Link to={`/purchase_details/${text}`}><EditOutlined style={{color:'blue',':hover':{color:'white'}}} /></Link>
          </Popover>
      
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to delete sale</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => {
              axios.delete(`${serverconfig.backendserverurl}/api/purchase_receipts/${text}`).then(res =>{
                window.location.reload(false)
                message.info("successfully deleted") 
              }
              );

          }}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>
          </p>,
        }
  
      ];
    
   
    return(
        <div>  

        <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              purchase_receipts={this.state.purchase_receipts}
              ref={el => (this.componentRef = el)} /> 
            </div>

    <Tabs defaultActiveKey="1" onChange={()=>{}}>

            <TabPane tab="RECEIPT TRACKER" key="1">
            <div style={{display:'flex',flexDirection:'row'}}>

<a style={{margin:10}}>
  <ReactToPrint
    trigger={() =>                     
  <div style={{}}>
    <Tooltip title="Click to print" placement="top">
    <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '25px', color: '#08c' }}/></p>
    <h6 style={{alignSelf:'center'}}>Print </h6>
    </Tooltip>
  </div>                          
    }
    content={() => this.componentRef}
  />  
</a>

<ExcelFile filename={"Receipt tracker from "+this.state.dateone+" to "+this.state.datetwo}  element={<Button type='primary' icon={<DownloadOutlined/>} >Download excel</Button>}>
    <ExcelSheet data={this.state.purchase_receipts} name="Purchases">
        <ExcelColumn label="Date" value="date"/>
        <ExcelColumn label="Team name" value="team_name"/>
        <ExcelColumn label="Team contact" value="tel_contact"/>                      
        <ExcelColumn label="Station" value="station_name"/>
        <ExcelColumn label="Distributor" value="distributor_name"/>
        <ExcelColumn label="Distributor receipt no" value="distributer_receiptno"/>
        <ExcelColumn label="Total" value="totalamount"/>

    </ExcelSheet>
</ExcelFile>

</div>
<br></br>
            <div style={{display:'flex',flexDirection:'row'}}>

           <FormItem label="Search by station name">
              <Search
                    placeholder="Enter station name,team name or distributor"
                    onChange={(value) => {
                      var val=value.target.value
                      console.log(val)
                      if (val !== '' || val !== undefined) {
                        //set one is by NAME
                        const results_1 = this.state.purchase_receipts.filter((request) => {
                          return String(request.station_name).toLowerCase().startsWith(val.toLowerCase());
                          // Use the toLowerCase() method to make it case-insensitive
                        });
                  
                        if (results_1.length === 0) {
                      
                          const results_2 = this.state.purchase_receipts.filter((request) => {
                            return String(request.team_name).toLowerCase().startsWith(val.toLowerCase());
                            // Use the toLowerCase() method to make it case-insensitive
                          });

                          if (results_2.length === 0) {

                            const results_3 = this.state.purchase_receipts.filter((request) => {
                              return String(request.distributor_name).toLowerCase().startsWith(val.toLowerCase());
                              // Use the toLowerCase() method to make it case-insensitive
                            });

                            if (results_3.length === 0) {
                              this.setState({purchase_receipts:this.state.purchase_receipts_placeholders});

                            }else{
                              this.setState({purchase_receipts:results_3});

                            }


                          }else{
                            this.setState({purchase_receipts:results_2});
                          }

                        } else {
                          this.setState({purchase_receipts:results_1});
                          console.log(results_1)
                        }
                  
                      } else {
                        this.setState({purchase_receipts:this.state.purchase_receipts_placeholders});
                      }
                  
                  }}
                  style={{
                    width: 400,
                    margin:5
                  }}
                  />
              </FormItem>

                {
                  this.state.dataloading?
                  <Spin size="large">
                  <div className="content" />
                  </Spin>   
                  :
                  <Form  layout="inline" >
                  <FormItem label="Date range">
                      <RangePicker defaultValue={moment()} 
                      onChange={(date, dateString) =>{
                        this.setState({ dateone: dateString[0]});
                        this.setState({ datetwo: dateString[1]});
                      }}
                        format={dateFormat} />
                  </FormItem>

                  <FormItem label="Search type">
                  <Select 
                  placeholder="Search type" 
                  value={this.state.search_type}
                  onChange={(val)=>{
                    this.setState({search_type:val})
                    //set details 
                  }} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}      
                  style={{
                    width: 200,
                    margin:5
                  }}             
                  >
                        <Option value={'All'}>All</Option>
                        <Option value={'by_team'}>Team</Option>
                        <Option value={'by_distributor'}>Distributor</Option>
                        <Option value={'by_station'}>Station</Option>
                  </Select>
                  </FormItem>

                  {
                    this.state.search_type==="by_station"?
                    <FormItem label="Station">
                    <Select 
                    placeholder="Station" 
                    value={this.state.station}
                    onChange={(val)=>{
                      this.setState({station:val})
                      //set details 
                    }} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}   
                    style={{
                      width: 200,
                      margin:5
                    }}                
                    >
                      {this.state.stations.map(
                        (accnt)=>(
                          <Option value={accnt.id}> {accnt.station_name}</Option>
                        ))}
                    </Select>
                    </FormItem>
                    :
                    null
                  }

                 

                {
                    this.state.search_type==="by_distributor"?

                  <FormItem label="Distributor">
                    <Select placeholder="Distributor" 
                    value={this.state.distributer} onChange={(val)=>{
                      this.setState({distributer:val})
                    }} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}    
                    style={{
                      width: 200,
                      margin:5
                    }}
                    >
                      {this.state.distributers.map(
                        (dst)=>(
                          <Option value={dst.id}>{dst.name}</Option>
                        ))}
                    </Select>
                      </FormItem>
                       :
                       null
                     }


                  {
                    this.state.search_type==="by_team"?

                      <FormItem label="Team">
                        <Select 
                        placeholder="Team" 
                        value={this.state.team}
                        onChange={(val)=>{
                          this.setState({team:val})
                          //set details 
                        }} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}     
                        style={{
                          width: 200,
                          margin:5
                        }}              
                        >
                          {this.state.teams.map(
                            (accnt)=>(
                              <Option value={accnt.id}> {accnt.team_name} | {accnt.tel_contact}</Option>
                            ))}
                        </Select>
                    </FormItem>
                      :
                      null
                    }


                  <FormItem>
                  <Button onClick={()=>{

                      let form_data = new FormData();
                      form_data.append('dateone', this.state.dateone);
                      form_data.append('datetwo', this.state.datetwo);
                      form_data.append('station', this.state.station);
                      form_data.append('team', this.state.team);
                      form_data.append('distributer', this.state.distributer);
                      form_data.append('search_type', this.state.search_type);

                      if(this.state.dateone===''||this.state.datetwo===''){
                        message.error("Dates missing")
                      }else if (this.state.search_type==="by_station" && this.state.station===""){
                        message.error("Station missing")

                      }else if (this.state.search_type==="by_team" && this.state.team===""){
                        message.error("Team missing")

                      }else if (this.state.search_type==="by_distributor" && this.state.distributer===""){
                        message.error("Distributer missing")

                      }else{
                          this.setState({dataloading:true})

                          this.setState({datarequested:true})
                          this.setState({datasearch:true})

                          this.setState({purchase_receipts:[]})
                          this.setState({purchase_receipts_placeholders:[]})

                          //Now submit sale data to database
                          axios.post(serverconfig.backendserverurl+'/customqueries/getpurchases_report', form_data,{
                          headers: {
                            'content-type': 'multipart/form-data'
                          }
                          })
                          .then(res =>{
                            this.setState({datarequested:false})
                            this.setState({purchase_receipts:JSON.parse(res.data.report)})
                            this.setState({purchase_receipts_placeholders:JSON.parse(res.data.report)})

                           this.setState({dataloading:false})

                          }).catch(error => console.log(error))   
                      }

                      }} type="primary" htmlType="button">Search</Button>
                  </FormItem> 
                  </Form>
                }

          </div>


              <br></br>
            


              <Table columns={columns} 
              pagination={{showQuickJumper:true,showSizeChanger:true }}
              dataSource={this.state.purchase_receipts}
              scroll={{ x: 1000 }}
              bordered/>
              <br></br>

              {
              this.state.userrights.can_enter_purchase?
              <PurchaseReciptView/>
              :
              null}
              
            </TabPane>

            <TabPane tab="Team Transactions" key="2">
              <TeamJournalsList/>
            </TabPane>


            <TabPane tab="Station Transactions" key="3">
              <StationJournalsList />

            </TabPane>

            {/*<TabPane tab="Team payment summary" key="4">
              <TeamPaymentList />

            </TabPane>*/}
             
    </Tabs>
    </div>
    )
  }
}

export default PurchaseList; 

import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Popconfirm,Popover,
  Collapse,Avatar,Drawer,Descriptions,message,Modal,Spin,Switch} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,FundOutlined,FundViewOutlined,DeleteOutlined,MailOutlined,LockFilled,LockOutlined,LoadingOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import DistributerForm from '../components/DistributerForm'
import PhoneInput from 'react-phone-number-input'

var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const { Panel } = Collapse;
const FormItem=Form.Item;

const { Option } = Select;

function callback(key) {
  console.log(key);
}

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var sacco= ''
var username=''
var token= ''
var ngoid= ''

class DistributerList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    distributers: [],
    rightsmodalvisible:false,
    rights: {},
    selectedid:'',
    datarequested:true,

    can_view_dashboard:true,
    can_enter_doctor:true,
    can_view_doctors:true,

    can_enter_speciality:true,
    can_view_speciality:true,

    can_enter_patient:true,
    can_view_patients:true,

    can_enter_newcase:true,
    can_edit_case:true,
    can_view_allcases:true,

    can_create_patient_reviews:true,
    can_edit_patient_reviews:true,
    can_view_patient_reviews:true,
    
    can_assign_cases:true,
    can_create_case_review:true,
    can_view_case_reviews:true,

    showupdate_model:false,

    name:'',
    station_name:'' ,  
    district:'',
    address:'',
    manager:'',
    distributor_id:'',

    phone:'',
    email:'',
    account_no:'',
    commission_percentage:0,
    station:'',
    stations: [],

   
  };

  displaypasswordupdatemodal=(id)=>{
    this.setState({showpasswordupdatemodal:true})
    this.setState({selectedid:id})
  }
  
  closepasswordupdatemodal=()=>{
    this.setState({showpasswordupdatemodal:false})
  }
  


  //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/distributers/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };


  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ rightsmodalvisible: false });
  };




  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('district', this.state.district);
    form_data.append('address', this.state.address);
    form_data.append('station', this.state.station);

    form_data.append('phone', this.state.phone);
    form_data.append('commission_percentage', this.state.commission_percentage);
    form_data.append('email', this.state.email);
    form_data.append('account_no', this.state.account_no);

      axios.put(serverconfig.backendserverurl+`/api/distributers/${this.state.distributor_id}/`, form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
      ngoid= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/distributers/`)
    .then(res => {
        this.setState({
          distributers:res.data
        })

        //this.setState({datarequested:false})

    })

    axios.get(serverconfig.backendserverurl+`/api/accounts/`)
    .then(res => {
        this.setState({
          accounts:res.data
        })

        this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/stations/`)
    .then(res => {
        this.setState({
          stations:res.data
        })

    })

}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [
     
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'id',
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'District',
        dataIndex: 'district',
        key: 'id',
        ...this.getColumnSearchProps('district'),
      },
      {
        title: 'Station',
        dataIndex: 'station_name',
        key: 'id',
        ...this.getColumnSearchProps('station_name'),
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'id',
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'id',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'id',
      },

      {
        title: 'Account no',
        dataIndex: 'account_no',
        key: 'id',
      },
      {
        title: 'Commission percentage',
        dataIndex: 'commission_percentage',
        key: 'id',
      },

      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <FundViewOutlined style={{color:'blue'}} onClick={()=>{
            this.setState({distributor_id:record.id})
            this.setState({name:record.name})

            this.setState({station:record.station})
            this.setState({address:record.address})
            this.setState({district:record.district})

            this.setState({email:record.email})
            this.setState({phone:record.phone})
            this.setState({commission_percentage:record.commission_percentage})
            this.setState({account_no:record.account_no})


            this.setState({showupdate_model:true})

         }}/>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete station</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      },

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>       
        </div>
      )

    }else{

      return(
        <div>
           <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL distributors" key="1">
            <Table 
             scroll={{ x: 1000 }}
             columns={columns}
             pagination={{showQuickJumper:true,showSizeChanger:true }}
             dataSource={this.state.distributers} bordered/>
            </Panel>
            </Collapse>
            <br></br>
            <DistributerForm />
            <br></br>

        <Modal
              visible={this.state.showupdate_model}
              title="Distributer update form"
              //width={200}
              onCancel={(val)=>{this.setState({showupdate_model:false})}}  
              footer={[
                <Button key="back" onClick={(val)=>{this.setState({showupdate_model:false})}}>
                  Cancel
                </Button>
                ]}
                >
           <Form
                onFinish={(event) => this.handleFormSubmit(event)}
            >
<FormItem label="Name"
        
      >
        <Input name="name"  placeholder="Put a name here." value={this.state.name} onChange={(val)=>{this.setState({name:val.target.value})}} />
      </FormItem>
      
      <FormItem label="District"
    
      >
        <Input name="district"  placeholder="district here" value={this.state.district} onChange={(val)=>{this.setState({district:val.target.value})}} />
      </FormItem>

      <FormItem label="Address"
         
      >
        <Input name="address"  placeholder="address here" value={this.state.address} onChange={(val)=>{this.setState({address:val.target.value})}} />
      </FormItem>

      <FormItem label= { <h6 style={{display:'flex'}} >Phone number</h6>}                       
          >
          <PhoneInput
                countrySelectProps={{ unicodeFlags: true }}
                defaultCountry="UG"
                placeholder="Enter phone number"
                value={this.state.phone} onChange={(val)=>{this.setState({phone:val})}}/>
     </FormItem> 



      <FormItem label="Email Address"
                   
      
      >
      <Input  type="mail" 
              prefix={<MailOutlined className="site-form-item-icon" />} 
              placeholder="Email"
              value={this.state.email} 
              onChange={(val)=>{this.setState({email:val.target.value})}} />
      </FormItem>

      <FormItem label="Account number"
                    
        >
          <Input placeholder="Account number." value={this.state.account_no} onChange={(val)=>{this.setState({account_no:val.target.value})}} />
        </FormItem>

        <FormItem label="Commission percentage on sale"
                     
        >
          <Input placeholder="Commission." value={this.state.commission_percentage} onChange={(val)=>{this.setState({commission_percentage:val.target.value})}} />
        </FormItem>


      <FormItem label="Station"
          
          >
              <Select 
              placeholder="station" 
              style={{  }} 
              value={this.state.station}
              onChange={(val)=>{this.setState({station:val})}} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}
                                  
              >
                {this.state.stations.map(
                  (accnt)=>(
                    <Option value={accnt.id}> {accnt.station_name}</Option>
                  ))}
              </Select>
          </FormItem>


            <FormItem>
              <Button  type="primary" htmlType="submit">Update</Button>
            </FormItem>
          </Form>
          
         </Modal>
        </div>
    )
    }
   
  }
}

export default DistributerList; 

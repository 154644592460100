import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Statistic,Tabs,Modal } from 'antd';
import { ArrowUpOutlined,MinusCircleFilled,ToolOutlined, ArrowDownOutlined,FileFilled,WalletFilled,SearchOutlined,FundViewOutlined,
   UserOutlined, AccountBookOutlined,CalculatorFilled,SwapOutlined,GroupOutlined,AccountBookFilled, DollarCircleOutlined,EditFilled,MessageFilled,DollarOutlined,CarFilled,LoadingOutlined,PlusCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import { Tooltip,Popover,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../serverconn'
import * as serversocketconfig from '../serversocketconfig'
import { MdShoppingCart } from 'react-icons/md';

import CurrencyFormat from 'react-currency-format';
import Highlighter from 'react-highlight-words';
import Websocket from 'react-websocket';
import { Link } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { NavLink } from 'react-router-dom'
import * as primarycolor from '../primarycolor'
import  QuickMenu from '../containers/QuickMenu'

var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;

var bizuserid= ''
var ngoid= ''
var token= ''


class DashBoard extends React.Component {

  state = {
    currentmonth:moment().format(monthdateFormat).toString(),
    companyprofile:{},
    datarequested:true,
    memberformvisible:false,
    transactformvisible:false,
    calculatorformvisible:false,
    members:[],
    loansformvisible:false,
    dashboardlocked:false,
    utilitiesmodalvisible:false,
    userprofile:{},
    userrights:{},
    nonworkingday:{},
    depositsno:0,
    withdrawsno:0,
    depositvolume:0,
    withdrawvolume:0,
    tranquerymode:'daily',
    expensevolumes:0,
    incomevolumes:0,
    datareload:false,
    withdrawpercentage:0,
    withdrwdiff:0,
    depositpercentage:0,
    depositdiff:0,
    percenategincomes:0,
    incomediff:0,
    percenategexpnses:0,
    expensdiff:0,
    weeklydays:[],
    weeklydeposits:[],
    weeklywithdraws:[],
    weeklyincomes:[],
    weeklyexpenses:[],

    weeklychartdata:[],
    weeklychartitems:[],

    graphtranquerymode:'weekly',
    loanproccessuerymode:'daily',

    totalapplications:0,
    totalapplicationamounts:0,
    totalassessments:0,
    totalassessmentsamounts:0,
    totalapprovals:0,
    totalapprovalsamounts:0,
    totaldisbursements:0,
    totaldisbursementsamounts:0,

    projectedmonths:"three",
    monthsprojected:[],
    projectedinterest:[],
    projectedprinciple:[],

    applicationvsdates:[],
    applicationvs:[],
    issuancesvs:[],
    applicvsmode:'weekly',

    children:[],
    otherngos:[],
    doctors:[],
    patients:[],
    pendingcases:[],
    underreviewcases:[],
    reviewedcases:[],

    group_case_assignments:[],
    myassignments:[],
    doctorid:'',
    mygroup_assignments:[],

    teams:[],
    stations:[],
    distributers:[],

    tranquerymode:'Daily',
    stat_totals:{},
    datareload:true,



  };


  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){
    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })
  


    axios.get(serverconfig.backendserverurl+`/api/teams/`)
    .then(res => {
        this.setState({
          teams:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/distributers/`)
    .then(res => {
        this.setState({
          distributers:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/stations/`)
    .then(res => {
        this.setState({
          stations:res.data
        })

        this.setState({datarequested:false})

    })


    //get first statistics
    let form_data = new FormData();
    form_data.append('tranquerymode', this.state.tranquerymode);

    //Now send the post resquest
      axios.post(serverconfig.backendserverurl+'/customqueries/gettransactiontatistics', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
      this.setState({stat_totals:res.data.feedback})
      this.setState({datareload:false})

    })
    .catch(error => console.log(error)) 

}


//get stat data
gettransactiondata=(val)=>{
  this.setState({datareload:true})

  //get first statistics
  let form_data = new FormData();
  form_data.append('tranquerymode', val);

  //Now send the post resquest
    axios.post(serverconfig.backendserverurl+'/customqueries/gettransactiontatistics', form_data, {
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
  .then(res => {
    this.setState({stat_totals:res.data.feedback})
    this.setState({datareload:false})

  })
  .catch(error => console.log(error)) 


}


render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>    
        </div>
      )
  
    }else{

        return(
          <div className="animated fadeIn">  
          {
          this.state.userrights.can_view_dashboard===true?
          <div>
             <h3 style={{fontStyle:'oblique',fontWeight:'bolder',color:primarycolor.primarycolor}}>{this.state.companyprofile.company_name}</h3>

          <Row>

          <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
          <NavLink to='/teams/'> 

          <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>

          <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
          <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> TEAMS </h5>
          <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.teams.length}</h6>

          <h3 style={{display:'flex',fontSize:18,fontWeight:'bold'}}>Active teams : {this.state.stat_totals.active_tTotal} </h3>
          <h3 style={{display:'flex',fontSize:18,fontWeight:'bold'}}>Inactive teams: {this.state.stat_totals.inactive_tTotal} </h3>

          </div>

            </Card>
            </NavLink>
         </Col>

        <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
        <NavLink to='/stations/'> 

          <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>

        <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
        <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> STATIONS </h5>
        <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.stations.length}</h6>
        </div>

          </Card>
          </NavLink>
        </Col>

        <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
        <NavLink to='/distributors/'> 

          <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>

        <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
        <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> DISTRIBUTORS </h5>
        <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.distributers.length}</h6>
        </div>

          </Card>
          </NavLink>
        </Col>

        <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
          
          <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>

        <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
        <Select 
          value={this.state.tranquerymode} 
          onChange={(val)=>{this.setState({tranquerymode:val})
          //query data
           this.gettransactiondata(val)
        
        }}
          placeholder="Query Mode" 
          style={{display:'flex',marginRight:3}}
          >
            <Option value='Daily'>Daily</Option>
            <Option value='Weekly'>Weekly</Option>
            <Option value='Monthly'>Monthly</Option>
          </Select>

          {
            this.state.datareload===true?
            <Spin indicator={antIcon} />
            :null
          }

        </div>

        <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
        <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> {this.state.tranquerymode} Team entries </h5>
            <h6 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>{this.state.stat_totals.teams_total}</h6>

           </div>


          </Card>
        </Col>


        <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
          <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>

        <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
        <Select 
          value={this.state.tranquerymode} 
          onChange={(val)=>{this.setState({tranquerymode:val})
          //query data
           this.gettransactiondata(val)
        
        }}
          placeholder="Query Mode" 
          style={{display:'flex',marginRight:3}}
          >
            <Option value='Daily'>Daily</Option>
            <Option value='Weekly'>Weekly</Option>
            <Option value='Monthly'>Monthly</Option>
          </Select>

          {
            this.state.datareload===true?
            <Spin indicator={antIcon} />
            :null
          }

        </div>

        <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
        <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> {this.state.tranquerymode} Team purchases </h5>
            <h3 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>
              
              <CurrencyFormat value={this.state.stat_totals.team_purchases} displayType={'text'} thousandSeparator={true}   />
              </h3>

           </div>

          </Card>
        </Col>


        <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
          <Card style={{display:'flex',alignItems:'center',justifyContent:'center'}}>

        <div style={{display:'flex',alignItems:'flex-end',justifyContent:'flex-end',flexDirection:'row'}}>
        <Select 
          value={this.state.tranquerymode} 
          onChange={(val)=>{this.setState({tranquerymode:val})
          //query data
           this.gettransactiondata(val)
        
        }}
          placeholder="Query Mode" 
          style={{display:'flex',marginRight:3}}
          >
            <Option value='Daily'>Daily</Option>
            <Option value='Weekly'>Weekly</Option>
            <Option value='Monthly'>Monthly</Option>
          </Select>

          {
            this.state.datareload===true?
            <Spin indicator={antIcon} />
            :null
          }

        </div>

        <div style={{display:'flex',alignItems:'center',justifyContent:'center',alignContent:'center',flexDirection:'column'}}>
        <h5 style={{display:'flex',marginRight:5,fontSize:16,color:primarycolor.primarycolor}}> {this.state.tranquerymode} Commissions </h5>
            <h3 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>Total: <CurrencyFormat value={this.state.stat_totals.total_commission} displayType={'text'} thousandSeparator={true}   /></h3>
            <h3 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>Total (teams/upline) : <CurrencyFormat value={this.state.stat_totals.total_commission_out} displayType={'text'} thousandSeparator={true}   /></h3>
            <h3 style={{display:'flex',fontSize:22,fontWeight:'bold'}}>Total dividends: <CurrencyFormat value={this.state.stat_totals.dividend_total} displayType={'text'} thousandSeparator={true}   /></h3>


           </div>

          </Card>
        </Col>



        </Row>

          </div>

          :
          null}
          <br></br>
          <QuickMenu />

       </div>
      )
    }
  
 

}
}

export default DashBoard; 

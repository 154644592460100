import React from 'react';
import { Form, Input, Button,Spin,Select } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;


var token= ''
var sacco= ''
var ngoid= ''


class StationForm extends React.Component {

  state = {
    station_name:'' ,  
    district:'',
    address:'',
    datarequested:false,
    accounts: [],
    manager:'',



  }

  componentDidMount(){
    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accounts/`)
    .then(res => {
        this.setState({
          accounts:res.data
        })

        this.setState({datarequested:false})
    })
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('station_name', this.state.station_name);
    form_data.append('district', this.state.district);
    form_data.append('address', this.state.address);
    form_data.append('manager', this.state.manager);

      axios.post(serverconfig.backendserverurl+'/api/stations/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

  }

  handlespecilaityChange= (e) => this.setState({ station_name: e.target.value});
  handleIDdistrictChange= (e) => this.setState({ district: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>  
        </div>
      )

    }else{
      return (
        <div>
          <h4>Station form.</h4>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Station name"
        name="station_name"
        rules={[
          {
            required: true,
            message: 'Please input station_name',
          },
        ]}
      >
        <Input name="station_name"  placeholder="Put a name here." value={this.state.station_name} onChange={(val)=>{this.setState({station_name:val.target.value})}} />
      </FormItem>
      
      <FormItem label="District"
        name="district"
        rules={[
          {
            required: true,
            message: 'Please input desc',
          },
        ]}    
      >
        <Input name="district"  placeholder="district here" value={this.state.district} onChange={(val)=>{this.setState({district:val.target.value})}} />
      </FormItem>

      <FormItem label="Address"
        name="address"
        rules={[
          {
            required: true,
            message: 'Please input desc',
          },
        ]}    
      >
        <Input name="address"  placeholder="address here" value={this.state.address} onChange={(val)=>{this.setState({address:val.target.value})}} />
      </FormItem>


      <FormItem label="Branch manager"
            name='manager'
            rules={[
              {
                required: true,
                message: 'Please Select manager',
              },
            ]}
          >
              <Select 
              placeholder="Manager" 
              style={{  }} 
              value={this.state.manager}
              onChange={(val)=>{this.setState({manager:val})}} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}
                                  
              >
                {this.state.accounts.map(
                  (accnt)=>(
                    <Option value={accnt.id}> {accnt.username}</Option>
                  ))}
              </Select>
          </FormItem>
    

            <FormItem>
              <Button  type="primary" htmlType="submit">Create</Button>
            </FormItem>
          </Form>
        </div>
      );

    }
   
  }

}


export default StationForm;




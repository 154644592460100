import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Popconfirm,Tooltip,Form,message,Popover,Spin,Modal,Descriptions,DatePicker,Select } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { DeleteOutlined } from '@ant-design/icons';
import { SearchOutlined,EditOutlined,WalletFilled,DownloadOutlined,PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import PurchaseReciptView from './PurchaseReceiptView'
import {  Slider,Statistic,Tabs } from 'antd';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';

var CryptoJS = require("crypto-js");
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;

const { Panel } = Collapse;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { Search } = Input;
const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;


message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var bizuserid= ''
var token= ''

class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

  render() {
    return (
      <div style={{padding:20}}>

        <style>
          {`@media print {
            @page { size: landscape; }
          }`}
          </style>
<Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.company_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
               
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
              
                </Col>

     </Row>

           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>TEAM TRANSACTION STATEMENT FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>

           
           <reactstrp.Table bordered>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Journal no</th>
                        <th>Team name</th>
                        <th>Team contact</th>
                        <th>Station name</th>
                        <th>Description</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Account balance</th>

                      </tr>

                    </thead>
                    <tbody>
                    {this.props.teamtransactions_journals.map(
                      (item)=>(
                        <tr>
                        <td>{item.date}</td>
                        <td>{item.journal_no}</td>
                        <td>{item.team_name}</td>
                        <td>{item.team_tel_contact}</td>
                        <td>{item.station_name}</td>
                        <td>{item.description}</td>

                        <td>{<CurrencyFormat value={item.Debit} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td>{<CurrencyFormat value={item.Credit} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td>{<CurrencyFormat value={item.account_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>
                      ))}
                      <tr>
                      <td style={{fontWeight:'bolder'}}>Total transctions {this.props.teamtransactions_journals.length}</td>
                      </tr>
                  </tbody>
            </reactstrp.Table> 

      </div>
    );
  }
}


class TeamJournalsList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    teamtransactions_journals: [],
    teamtransactions_journals_placeholder: [],

    viewpaymentmodal:false,
    receipttotalpayment:0,
    receiptdetails:{},
    userprofile:{},
    dateone:'',
    datetwo:'',
    team:'',
    teams:[],
    dataloading:false

  };

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }


    axios.get(serverconfig.backendserverurl+`/api/teamtransactions_journals/`)
    .then(res => {
        this.setState({
          teamtransactions_journals:res.data
        })

        this.setState({
          teamtransactions_journals_placeholder:res.data
        })
    })

    
    //get the user profile here
    axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}`,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/teams/`)
    .then(res => {
        this.setState({
          teams:res.data
        })
    })
}




  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  render() {

      var columns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Journal no',
          dataIndex: 'journal_no',
          key: 'id',
          ...this.getColumnSearchProps('journal_no'),
        },
        {
          title: 'Team name',
          dataIndex: 'team_name',
          key: 'id',
          ...this.getColumnSearchProps('team_name'),
        },
        {
          title: 'Team contact',
          dataIndex: 'team_tel_contact',
          key: 'id',
          ...this.getColumnSearchProps('team_tel_contact'),
        },
        {
          title: 'Station',
          dataIndex: 'station_name',
          key: 'id',
          ...this.getColumnSearchProps('station_name'),
        },
        {
          title: 'Txn details',
          dataIndex: 'description',
          key: 'id',
        },

        {
          title: 'Debit',
          dataIndex: 'Debit',
          key: 'id',
          render:text=><h4 style={{color:'green'}}><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  /></h4>  
        },
        {
          title: 'Credit',
          dataIndex: 'Credit',
          key: 'id',
          render:text=><h4 style={{color:'green'}}><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  /></h4>  
        },

        {
          title: 'Account balance',
          dataIndex: 'account_balance',
          key: 'id',
          render:text=><h4 style={{color:'green'}}><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  /></h4>  
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (text,record) => 
          <p>
          <Popover content={<p>Click here to view receipt details</p>} title="Receipt details">
          <Link to={`/purchase_details/${record.sale_receipt}`}><EditOutlined style={{color:'blue',':hover':{color:'white'}}} /></Link>
          </Popover>
      
          </p>,
        }
  
      ];
    

    return(
        <div>  

<div style={{display: "none"}}>
               &nbsp;&nbsp;
        <ReportToPrint
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              teamtransactions_journals={this.state.teamtransactions_journals}
              ref={el => (this.componentRef = el)} /> 
            </div>

            <div style={{display:'flex',flexDirection:'row'}}>

<a style={{margin:10}}>
  <ReactToPrint
    trigger={() =>                     
  <div style={{}}>
    <Tooltip title="Click to print" placement="top">
    <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '25px', color: '#08c' }}/></p>
    <h6 style={{alignSelf:'center'}}>Print </h6>
    </Tooltip>
  </div>                          
    }
    content={() => this.componentRef}
  />  
</a>

<ExcelFile  element={<Button type='primary' icon={<DownloadOutlined/>} >Download excel</Button>}>
  <ExcelSheet data={this.state.teamtransactions_journals} name="Team transactions">
      <ExcelColumn label="Date" value="date"/>
      <ExcelColumn label="Journal no" value="journal_no"/>
      <ExcelColumn label="Team name" value="team_name"/>
      <ExcelColumn label="Team contact" value="team_tel_contact"/>

      <ExcelColumn label="Station" value="station_name"/>
      <ExcelColumn label="Txn Desc" value="description"/>
      <ExcelColumn label="Debit" value="Debit"/>
      <ExcelColumn label="Credit" value="Credit"/>
      <ExcelColumn label="Acc balance" value="account_balance"/>
      
  </ExcelSheet>
</ExcelFile>

</div>
<br></br>

<div style={{display:'flex',flexDirection:'row'}}>

<FormItem label="Search by team name or phone">
            <Search
                  placeholder="Enter team phone or name"
                  onChange={(value) => {
                    var val=value.target.value
                    console.log(val)
                    if (val !== '' || val !== undefined) {
                      //set one is by NAME
                      const results_1 = this.state.teamtransactions_journals.filter((request) => {
                        return String(request.team_name).toLowerCase().startsWith(val.toLowerCase());
                        // Use the toLowerCase() method to make it case-insensitive
                      });
                
                      if (results_1.length === 0) {

                        const results_2 = this.state.teamtransactions_journals.filter((request) => {
                          return String(request.team_tel_contact).startsWith(val);
                          // Use the toLowerCase() method to make it case-insensitive
                        });

                        if (results_2.length === 0) {
                         this.setState({teamtransactions_journals:this.state.teamtransactions_journals_placeholder});
                        }else{
                          this.setState({teamtransactions_journals:results_2});
                        }
                     
                      } else {
                        this.setState({teamtransactions_journals:results_1});
                        console.log(results_1)
                      }
                
                    } else {
                      this.setState({teamtransactions_journals:this.state.teamtransactions_journals_placeholder});
                    }
                
                  }}
                  style={{
                    width: 400,
                    margin:5
                  }}
                  />
              </FormItem>


              {
                this.state.dataloading?
                <Spin size="large">
                 <div className="content" />
                </Spin>   
                :
                <Form  layout="inline" >
                <FormItem label="Search by date of entry">
                     <RangePicker defaultValue={moment()} 
                     onChange={(date, dateString) =>{
                       this.setState({ dateone: dateString[0]});
                       this.setState({ datetwo: dateString[1]});
                     }}
                      format={dateFormat} />
                 </FormItem>
   
                 <FormItem label="Team">
                 <Select 
                 placeholder="Team" 
                 style={{  }} 
                 value={this.state.team}
                 onChange={(val)=>{
                   this.setState({team:val})
                   //set details 
                 }} 
                 showSearch
                 optionFilterProp="children"
                 onFocus={this.onFocus}
                 onBlur={this.onBlur}
                 onSearch={this.onSearch}                   
                 >
                   <Option value={''}> All teams</Option>
                   {this.state.teams.map(
                     (accnt)=>(
                       <Option value={accnt.id}> {accnt.team_name} | {accnt.tel_contact}</Option>
                     ))}
                 </Select>
                </FormItem>
   
   
                 <FormItem>
                 <Button onClick={()=>{
                    this.setState({dataloading:true})
   
                     let form_data = new FormData();
                     form_data.append('dateone', this.state.dateone);
                     form_data.append('datetwo', this.state.datetwo);
                     form_data.append('team', this.state.team);
   
                     if(this.state.dateone===''||this.state.datetwo===''){
                       message.error("Dates missing")
                     }else{
                         this.setState({datarequested:true})
                         this.setState({datasearch:true})
   
                         this.setState({recruits:[]})
                         //Now submit sale data to database
                         axios.post(serverconfig.backendserverurl+'/customqueries/getteamjournals_report', form_data,{
                         headers: {
                           'content-type': 'multipart/form-data'
                         }
                         })
                         .then(res =>{
                           this.setState({datarequested:false})
                           this.setState({teamtransactions_journals:JSON.parse(res.data.report)})
                           this.setState({teamtransactions_journals_placeholder:JSON.parse(res.data.report)})
   
                           this.setState({dataloading:false})

                         }).catch(error => console.log(error))   
                     }
   
                     }} type="primary" htmlType="button">Search</Button>
                 </FormItem> 
                 </Form>
              }

  </div>


            <br></br>
            <br></br>
            




            <Table columns={columns} 
              pagination={{showQuickJumper:true,showSizeChanger:true }}
              dataSource={this.state.teamtransactions_journals}
              scroll={{ x: 1000 }}
              bordered/>   
        </div>
    )
  }
}

export default TeamJournalsList; 

import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Popconfirm,Popover,
  Collapse,Avatar,Drawer,Descriptions,message,Modal,Spin,Switch,Tabs,Tooltip,DatePicker} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,FundOutlined,FundViewOutlined,DeleteOutlined,MailOutlined,LockFilled,LockOutlined,LoadingOutlined } from '@ant-design/icons';
import { MailFilled,DownloadOutlined,PlusOutlined,PrinterOutlined,EditOutlined,CloseCircleOutlined,WalletFilled,FilePdfFilled } from '@ant-design/icons';

import { UserOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import TeamCreateForm from '../components/TeamCreateForm'
import TeamUpdateForm from '../components/TeamUpdateForm'

import PhoneInput from 'react-phone-number-input'
import ReactExport from "@ibrahimrahmani/react-export-excel";
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import moment from 'moment';

var CryptoJS = require("crypto-js");

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function callback(key) {
  console.log(key);
}

const { TabPane } = Tabs;
const { Panel } = Collapse;
const FormItem=Form.Item;

const { Option } = Select;
const { Search } = Input;


message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var sacco= ''
var username=''
var token= ''
var ngoid= ''
var bizuserid= ''


class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

//calculate total of bonus pay
calculateTotalPay=()=>{
  var total =0

  this.props.teams.map((item)=>{
    total+=Number(item.account_balance)

  })
  return total;
}


  render() {
    return (
      <div style={{padding:20}}>

        <style>
          {`@media print {
            @page { size: landscape; }
          }`}
          </style>
<Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.company_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
               
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
              
                </Col>

     </Row>

           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>PAYMENTS SUMMARY REPORT</h3>
    
           <reactstrp.Table bordered>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Station</th>
                        <th>Team name</th>
                        <th>Payment type</th>
                        <th>Pay institution</th>
                        <th>Phonenumber / Bank account</th>
                        <th>Account Bonus (Balance) </th>

                      </tr>
                    </thead>
                    <tbody>
                    {this.props.teams.map(
                      (item)=>(
                        <tr>
                        <td>{item.system_no}</td>
                        <td>{item.station_name}</td>
                        <td>{item.team_name}</td>
                        <td>{item.payment_type}</td>
                        <td>{item.payaccount_institution}</td>
                        <td>{item.bank_account}</td>
                        <td>{<CurrencyFormat value={item.account_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>
                      ))}
                      <tr>
                      <td style={{fontWeight:'bolder'}}>Total of teams {this.props.teams.length}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>

                      <th>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</th>

                      </tr>
                  </tbody>
              </reactstrp.Table> 
      </div>
    );
  }
}


class TeamList_PaymentSummary extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    teams: [],
    teams_placeholders: [],

    rightsmodalvisible:false,
    rights: {},
    selectedid:'',
    datarequested:true,

    can_view_dashboard:true,
    can_enter_doctor:true,
    can_view_doctors:true,

    can_enter_speciality:true,
    can_view_speciality:true,

    can_enter_patient:true,
    can_view_patients:true,

    can_enter_newcase:true,
    can_edit_case:true,
    can_view_allcases:true,

    can_create_patient_reviews:true,
    can_edit_patient_reviews:true,
    can_view_patient_reviews:true,
    
    can_assign_cases:true,
    can_create_case_review:true,
    can_view_case_reviews:true,

    showupdate_model:false,

    name:'',
    station_name:'' ,  
    district:'',
    address:'',
    manager:'',
    team_id:'',

    phone:'',
    email:'',
    account_no:'',
    commission_percentage:0,
    station:'',
    stations: [],

    selected_team:'',
    loan_particular_data_loading:false,
    downlines:[],

    dateone:moment().format(dateFormat).toString(),
    datetwo:moment().format(dateFormat).toString(),

  };

  displaypasswordupdatemodal=(id)=>{
    this.setState({showpasswordupdatemodal:true})
    this.setState({selectedid:id})
  }
  
  closepasswordupdatemodal=()=>{
    this.setState({showpasswordupdatemodal:false})
  }
  

  //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/teams/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };


  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ rightsmodalvisible: false });
  };




  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('name', this.state.name);
    form_data.append('district', this.state.district);
    form_data.append('address', this.state.address);
    form_data.append('station', this.state.station);

    form_data.append('phone', this.state.phone);
    form_data.append('commission_percentage', this.state.commission_percentage);
    form_data.append('email', this.state.email);
    form_data.append('account_no', this.state.account_no);

      axios.put(serverconfig.backendserverurl+`/api/teams/${this.state.team_id}/`, form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
      ngoid= ''
       username=''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      bizuserid= ''
   }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/customqueries/get_allteams`)
    .then(res => {
        this.setState({
          teams: JSON.parse(res.data.report)  
        })

        this.setState({
          teams_placeholders: JSON.parse(res.data.report)  
        })

        this.setState({
          teams_placeholders: JSON.parse(res.data.report)  
        })

        //this.setState({datarequested:false})

    })

    axios.get(serverconfig.backendserverurl+`/api/accounts/`)
    .then(res => {
        this.setState({
          accounts:res.data
        })

        this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/stations/`)
    .then(res => {
        this.setState({
          stations:res.data
        })

    })

}

//calculate total of bonus pay
calculateTotalPay=()=>{
  var total =0

  if(this.state.teams!=undefined){
    this.state.teams.map((item)=>{
      total+=Number(item.account_balance)
    })
  }

  return total;
}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [

      {
        title: 'System ID',
        dataIndex: 'system_no',
        key: 'id',
        ...this.getColumnSearchProps('system_no'),
      },
     
      {
        title: 'Station',
        dataIndex: 'station_name',
        key: 'id',
        ...this.getColumnSearchProps('station_name'),
      },

      {
        title: 'Name',
        dataIndex: 'team_name',
        key: 'id',
        ...this.getColumnSearchProps('team_name'),
      },

      {
        title: 'Payment type',
        dataIndex: 'payment_type',
        key: 'id',
        ...this.getColumnSearchProps('payment_type'),
      },
      {
        title: 'Pay institution',
        dataIndex: 'payaccount_institution',
        key: 'id',
        ...this.getColumnSearchProps('payaccount_institution'),
      },
      
      {
        title: 'Phone number / Bank account',
        dataIndex: 'bank_account',
        key: 'id',
        ...this.getColumnSearchProps('bank_account'),
      },
     
      {
        title: 'Account Bonus (Balance)',
        dataIndex: 'account_balance',
        key: 'id',
        render:text=><h4 style={{color:'green'}}><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  /></h4>  
      },

      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
        <Link to={`/team_update/${text}`}>
         <FundViewOutlined style={{color:'blue'}} />
         </Link>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete team</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      },

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>       
        </div>
      )

    }else{

      return(
        <div>

          <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              teams={this.state.teams!=undefined?this.state.teams:[]}
              ref={el => (this.componentRef = el)} /> 
            </div>


           <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="Payment summary report" key="1">
            <div style={{display:'flex',flexDirection:'row'}}>
              <a style={{margin:10}}>
                <ReactToPrint
                  trigger={() =>                     
                <div style={{}}>
                  <Tooltip title="Click to print" placement="top">
                  <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                  <h6 style={{alignSelf:'center'}}>Print </h6>
                  </Tooltip>
                </div>                          
                  }
                  content={() => this.componentRef}
                />  
              </a>

              <ExcelFile  element={<Button type='primary' icon={<DownloadOutlined/>} style={{margin:10}}>Download Excel </Button>} >
                <ExcelSheet data={this.state.teams} name={"Teams"}>
                    <ExcelColumn label="No" value="system_no"/>
                    <ExcelColumn label="Date of creation" value="date_of_creation"/>
                    <ExcelColumn label="Station" value="station_name"/>
                    <ExcelColumn label="Name" value="team_name"/>
                    <ExcelColumn label="Payment type" value="payment_type"/>
                    <ExcelColumn label="Pay institution" value="payaccount_institution"/>
                    <ExcelColumn label="Phone number / Bank account" value="bank_account"/>  
                    <ExcelColumn label="Account Bonus (Balance)" value="account_balance"/>
                </ExcelSheet>
               </ExcelFile>

              </div>
              <br></br>
              
              <Form  layout="inline" >
                  <FormItem label="Search by period of entry(Date Range)">
                      <RangePicker onChange={(date, dateString) =>{
                        this.setState({ dateone: dateString[0]});
                        this.setState({ datetwo: dateString[1]});
                      }} format={dateFormat} />
                  </FormItem>
  
                  <FormItem>
                  <Button onClick={()=>{

                    let form_data = new FormData();
                    form_data.append('dateone', this.state.dateone);
                    form_data.append('datetwo', this.state.datetwo);

                    console.log(this.state.entrant)
                      
                    if(this.state.entrant===''||this.state.dateone===''||this.state.datetwo===''){
                      alert("Please select entrant or dates missing")
                    }else{
                        this.setState({datarequested:true})

                        //Now submit sale data to database
                        axios.post(serverconfig.backendserverurl+'/customqueries/getteamsreport', form_data,{
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                        })
                        .then(res =>{
                          this.setState({datarequested:false})
                          this.setState({teams:JSON.parse(res.data.report)})

                          this.setState({
                            teams_placeholders: JSON.parse(res.data.report)  
                          })
                        } 
                        )
                        .catch(error => console.log(error))   
                    }

                  }} type="primary" htmlType="button">Search</Button>
                  </FormItem> 


              <FormItem label="Search by station name">
              <Search
                    placeholder="Enter station name"
                    onChange={(value) => {
                      var val=value.target.value
                      console.log("new: "+val)

                      if (val !== '' && val !== undefined &&  val !== null) {
                        //set one is by NAME
                        if (this.state.teams!=undefined){
                          const results_1 = this.state.teams.filter((request) => {
                            return String(request.station_name).toLowerCase().startsWith(val.toLowerCase());
                            // Use the toLowerCase() method to make it case-insensitive
                          });
                    
                          if (results_1.length === 0) {
                                this.setState({teams:this.state.teams_placeholders});
                          } else {
                            this.setState({teams:results_1});
                            console.log(results_1)
                          }
                        }else{
                          this.setState({teams:this.state.teams_placeholders});

                        }
                  
                      } else {
                        this.setState({teams:this.state.teams_placeholders});
                      }
                  
                  }}
                  style={{
                    width: 400,
                    margin:5
                  }}
                  />
              </FormItem>




           <FormItem label="Filter search by pay institution">
            <Search
                  placeholder="Pay institution"
                  onChange={(value) => {
                    var val=value.target.value
                    console.log(val)
                    if (val !== '' && val !== undefined &&  val !== null) {

                      const results_1 = this.state.teams.filter((request) => {
                        return String(request.payaccount_institution).toLowerCase().startsWith(val.toLowerCase());
                      });
                    
                      if (results_1.length === 0) {
                        this.setState({ teams: this.state.teams_placeholders });
                      } else {
                        this.setState({ teams: results_1 });
                        console.log(results_1);
                      }
                    
                    } else {
                      this.setState({teams:this.state.teams_placeholders});
                    }
                
                  }}
                  style={{
                    width: 400,
                    margin:5
                  }}
                  />
              </FormItem>
  
               </Form>

              <br></br>
            <Table 
             scroll={{ x: 1000 }}
             columns={columns}
             pagination={{showQuickJumper:true,showSizeChanger:true }}
             dataSource={this.state.teams} bordered
             
             expandable={{
              expandedRowRender: (record) =>{
                if (Number(record.id)===Number(this.state.selected_team)){
                  return (
                    <div
                      style={{
                        margin: 0,
                      }}
                     >

                      {
                        this.state.loan_particular_data_loading===true?
                        <div style={{display:'flex',margin:20}}>
                        <Spin indicator={antIcon} />
                        </div>
                        :

                      <Tabs defaultActiveKey="1" onChange={callback}>
                        <TabPane tab="Downlines" key="02" >

                          <reactstrp.Table bordered>
                          <thead>

                            <tr>
                              <th>System ID</th>
                              <th>Name</th>
                              <th>Contact</th>
                              <th>Residence</th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.downlines.map(
                            (item)=>(
                              <tr>
                              <td>{item.downline_no}</td>
                              <td>{item.downline_name}</td>
                              <td>{item.downline_phone}</td>
                              <td>{item.downline_residence}</td>
                            
                              </tr>
                            ))}
                           
                        </tbody>
                       </reactstrp.Table>
                         
                        </TabPane>
                        
                      </Tabs>

                      }

                    </div>
                    );
                }else{
                  return null;
                }
              } ,
              rowExpandable: (record) => record.id !== this.state.selected_team.id,
              onExpand:(condition,record)=>{
                this.setState({loan_particular_data_loading:true})

                //set curent id
                this.setState({selected_team:record.id})

                var teamID=record.id
                //get other data
                axios.get(`${serverconfig.backendserverurl}/api/upline_downlineprofile/?upline=${teamID}`)
                .then(res => {  
                    this.setState({downlines: res.data})            
                    this.setState({loan_particular_data_loading:false})
                })
            
              },
            }}
             
             />
             <br></br>
             <h2>Teams total: {this.state.teams!=undefined?this.state.teams.length:0}</h2>
             <h2>Payments total: <CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}  /></h2>

            </Panel>
            </Collapse>
          

        </div>
    )
    }

  }
}

export default TeamList_PaymentSummary; 

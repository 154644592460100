import React from 'react';
import { Form, Input, Button,Spin,Select, message,DatePicker } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';


var token= ''
var sacco= ''
var ngoid= ''


class TeamUpdateForm extends React.Component {

  state = {
    name:'' ,  
    occupation:'',
    residence:'',
    datarequested:true,
    accounts: [],
    station:'',
    stations: [],

    tel_contact:'',
    email:'',
    bank_account:'',
    age:0,

    date_of_creation:moment().format(dateFormat).toString(),
    team_name:'',
    gender:'',
    teams:[],
    upline_name:'',
    upline_contact:'',
    upline:'',

    payment_type:'',
    payaccount_institution:''
    

  }



  componentDidMount(){

    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accounts/`)
    .then(res => {
        this.setState({
          accounts:res.data
        })

        this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/stations/`)
    .then(res => {
        this.setState({
          stations:res.data
        })

        //this.setState({datarequested:false})

    })




    //get details of the
    axios.get(`${serverconfig.backendserverurl}/api/teams/${this.props.match.params.teamID}`)
    .then(res => {  

      //reset
        this.setState({team_name:''})
        this.setState({date_of_creation:''})
        this.setState({occupation:''})
        this.setState({residence:''})

        this.setState({station:''})
        this.setState({tel_contact:''})
        this.setState({email:''})
        this.setState({bank_account:''})

        this.setState({age:''})
        this.setState({gender:''})
        this.setState({upline_name:''})
        this.setState({upline_contact:''})
        this.setState({upline:''})

      //iupdate 
        this.setState({team_name:res.data.team_name})
        this.setState({date_of_creation:res.data.date_of_creation})
        this.setState({occupation:res.data.occupation})
        this.setState({residence:res.data.residence})

        this.setState({station:res.data.station})
        this.setState({tel_contact:res.data.tel_contact})
        this.setState({email:res.data.email})
        this.setState({bank_account:res.data.bank_account})

        this.setState({age:res.data.age})
        this.setState({gender:res.data.gender})
        this.setState({upline_name:res.data.upline_name})
        this.setState({upline_contact:res.data.upline_contact})
        this.setState({upline:res.data.upline_id})

        this.setState({payment_type:res.data.payment_type})
        this.setState({payaccount_institution:res.data.payaccount_institution})

        axios.get(serverconfig.backendserverurl+`/api/teams/?station=${res.data.station}`)
        .then(res => {
            this.setState({
              teams:res.data
            })
            //this.setState({datarequested:false})
        })

        //stat
        this.setState({datarequested:false})

    })


  }





//reset dataa
  resetdata=()=>{
    this.setState({team_name:''})
    this.setState({date_of_creation:''})
    this.setState({occupation:''})
    this.setState({residence:''})

    this.setState({station:''})
    this.setState({tel_contact:''})
    this.setState({email:''})
    this.setState({bank_account:''})

    this.setState({age:0})
    this.setState({gender:''})
    this.setState({upline_name:''})
    this.setState({upline_contact:''})

  }


  //submit button pressed
  handleFormSubmit=(event) =>{

    if(this.state.tel_contact===""){
      message.error("Phone number missing")
    }else{

      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('date_of_creation', this.state.date_of_creation);

      form_data.append('team_name', this.state.team_name);
      form_data.append('occupation', this.state.occupation);
      form_data.append('residence', this.state.residence);
      form_data.append('station', this.state.station);
  
      form_data.append('tel_contact', this.state.tel_contact);
      form_data.append('email', this.state.email);
      form_data.append('bank_account', this.state.bank_account);

      form_data.append('age', this.state.age);
      form_data.append('gender', this.state.gender);

      form_data.append('upline_contact', this.state.upline_contact);
      form_data.append('upline_name', this.state.upline_name);
      form_data.append('upline_id', this.state.upline);

      form_data.append('payment_type', this.state.payment_type);
      form_data.append('payaccount_institution', this.state.payaccount_institution);


      axios.put(serverconfig.backendserverurl+`/api/teams/${this.props.match.params.teamID}/`, form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

    }
  }

  


  handlespecilaityChange= (e) => this.setState({ name: e.target.value});
  handleIDoccupationChange= (e) => this.setState({ occupation: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>  
        </div>
      )

    }else{
      return (
        <div>
     <h4>Team creation form.</h4>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >

      <FormItem label={"Date ("+this.state.date_of_creation+" )"}
       
      >
          <DatePicker onChange={(date, dateString) => this.setState({ date_of_creation: dateString})} format={dateFormat} />
      </FormItem>


      <FormItem label="Team name"
      
      >
        <Input name="name"  placeholder="Put a name here." value={this.state.team_name} onChange={(val)=>{this.setState({team_name:val.target.value})}} />
      </FormItem>
      


      <FormItem label="Occupation"
         
      >
        <Input name="occupation"  placeholder="occupation here" value={this.state.occupation} onChange={(val)=>{this.setState({occupation:val.target.value})}} />
      </FormItem>



      <FormItem label="Residence"
           
      >
        <Input name="address"  placeholder="address here" value={this.state.residence} onChange={(val)=>{this.setState({residence:val.target.value})}} />
      </FormItem>


      <FormItem label= { <h6 style={{display:'flex'}} >Phone contact</h6>} >
          <PhoneInput
                countrySelectProps={{ unicodeFlags: true }}
                defaultCountry="UG"
                placeholder="Enter phone number"
                value={this.state.tel_contact} onChange={(val)=>{this.setState({tel_contact:val})}}/>
     </FormItem> 



      <FormItem label="Email Address"
                   
      
      >
      <Input  type="mail" 
              prefix={<MailOutlined className="site-form-item-icon" />} 
              placeholder="Email"
              value={this.state.email} 
              onChange={(val)=>{this.setState({email:val.target.value})}} />
      </FormItem>



      <FormItem label="Team payment type"
                  
        >
            <Select placeholder="Payment type" 
            style={{ width: 120 }} value={this.state.payment_type} 
            onChange={(val)=>{this.setState({payment_type:val})}} >
                  <Option value="mobilemoney">MobileMoney</Option>
                  <Option value="bank">Bank</Option>
            </Select>
      </FormItem>

      <FormItem label="Payment institution"
                   
        >
            <Select placeholder="Payment institution" 
            style={{ width: 120 }} value={this.state.payaccount_institution} 
            onChange={(val)=>{this.setState({payaccount_institution:val})}} >

                {
                  this.state.payment_type==="mobilemoney"?
                  <Option value="mtnmobilemoney">MTN Mobile</Option>

                  :
                  null
                }

                {
                  this.state.payment_type==="mobilemoney"?
                  <Option value="airtelmoney">Airtel money</Option>
                  :
                  null
                }


                {
                  this.state.payment_type==="bank"?
                  <Option value="centenary"> Centenary</Option>
                  :
                  null
                }
                                {
                  this.state.payment_type==="bank"?
                  <Option value="equity">Equity</Option>
                  :
                  null
                }
                                {
                  this.state.payment_type==="bank"?
                  <Option value="stanbic">Stanbic</Option>
                  :
                  null
                }
                                {
                  this.state.payment_type==="bank"?
                  <Option value="dfcu">DFCU</Option>
                  :
                  null
                }

            </Select>
      </FormItem>


      <FormItem label="Mobile money number / Account number "
            name="accountnumber"
            rules={[
              {
                required: false,
                message: 'Please enter account',
              },
            ]}           
        >
          <Input placeholder="Account number." value={this.state.bank_account} onChange={(val)=>{this.setState({bank_account:val.target.value})}} />
        </FormItem>



        <FormItem label="Age"
                     
        >
          <Input placeholder="Age." value={this.state.age} onChange={(val)=>{this.setState({age:val.target.value})}} />
        </FormItem>

        <FormItem label="Gender">
            <Select placeholder="Gender" style={{ width: 120 }} value={this.state.gender} onChange={(val)=>{this.setState({gender:val})}} >
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
            </Select>
        </FormItem>


      <FormItem label="Station">
              <Select 
              placeholder="station" 
              style={{  }} 
              value={this.state.station}
              onChange={(val)=>{this.setState({station:val})}} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}
                                  
              >
                {this.state.stations.map(
                  (accnt)=>(
                    <Option value={accnt.id}> {accnt.station_name}</Option>
                  ))}
              </Select>
          </FormItem>



      <FormItem label="Upline"
           
          >
              <Select 
              placeholder="upline" 
              style={{  }} 
              value={this.state.upline}
              onChange={(val)=>{
                
                this.setState({upline:val})
                //set details 

                axios.get(`${serverconfig.backendserverurl}/api/teams/${val}`)
                .then(res => {
                  this.setState({upline_contact:res.data.tel_contact})
                  this.setState({upline_name:res.data.team_name})

                })
              
              
              }} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}
                                  
              >
                {this.state.teams.map(
                  (accnt)=>{

                    if(this.props.match.params.teamID!=accnt.id){
                      return (<Option value={accnt.id}> {accnt.team_name} | {accnt.tel_contact}</Option>)
                    }

                  }
                  
                  
                  )
                  
                  }
              </Select>
          </FormItem>
    

          <FormItem>
            <Button  type="primary" htmlType="submit">Create</Button>
          </FormItem>
          </Form>
        </div>
      );

    }
   
  }

}


export default TeamUpdateForm;




import React from 'react';
import { Route } from 'react-router-dom';
import QuickMenu from './views/containers/QuickMenu'

import AccountList from './views/containers/AccountsListView'
import AccountDetail from './views/containers/AccountDetailView'
import DashBoard from './views/containers/DashBoard'

import UserroleList from './views/containers/UserrolesListView'
import UserroleDetail from './views/containers/UserroleDetailView'


import StationList from './views/containers/StationsListView'
import DistributerList from './views/containers/DistributorListView'
import UnitList from './views/containers/ProductUnitListView'
import ProductList from './views/containers/ProductListView'
import ProductDetail from './views/containers/ProductDetailView'
import TeamList from './views/containers/TeamListView'
import TeamUpdateForm from './views/components/TeamUpdateForm'
import PurchaseList from './views/containers/PurchaseListView'
import PurchaseReciptViewEdit from './views/containers/PurchaseReceiptViewEdit'

//new system here now
import CompanyProfile from './views/containers/companyProfile'
import JournalEntryView from './views/containers/JournalEntryView'
import CompanyAccountList from './views/containers/CompanyAccountsListView'

import TillSheetReport from './views/containers/TillSheetReportView'
import TrialBalanceReport from './views/containers/TrialBalanceView'
import LedgerReport from './views/containers/LedgerReportView'


const SaccoBaseRouter = () =>(
    <div>
        <Route exact path='/' component={DashBoard}  />
        <Route exact path='/quickmenu/' component={QuickMenu}  />

        <Route exact path='/useraccounts/' component={AccountList} />
        <Route exact path='/useraccounts/:accountID' component={AccountDetail} />

        <Route exact path='/userroles/' component={UserroleList} />
        <Route exact path='/userroles/:roleID' component={UserroleDetail}  />

        <Route exact path='/companyprofile/' component={CompanyProfile} />
        <Route exact path='/stations/' component={StationList} />
        <Route exact path='/distributors/' component={DistributerList} />
        <Route exact path='/productunits/' component={UnitList} />
        <Route exact path='/products/' component={ProductList} />
        <Route exact path='/products/:productID' component={ProductDetail} />

        <Route exact path='/teams/' component={TeamList} />
        <Route exact path='/team_update/:teamID' component={TeamUpdateForm} />

        <Route exact path='/purchases/' component={PurchaseList} />
        <Route exact path='/purchase_details/:purchaseID' component={PurchaseReciptViewEdit} />
       
        <Route exact path='/otherjournalentries/' component={JournalEntryView}  />
        <Route exact path='/companyaccounts/' component={CompanyAccountList}  />

        <Route exact path='/trialbalance/' component={TrialBalanceReport}  />
        <Route exact path='/ledgerreport/' component={LedgerReport}  />
        <Route exact path='/tillsheet/' component={TillSheetReport}  />

    </div>
);

export default SaccoBaseRouter;


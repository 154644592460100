import React,{ useRef } from 'react';
import axios from 'axios'
import { Tooltip,Popover,Table, Input,Popconfirm,Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Switch,Image,Result,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined,MailOutlined,DeleteOutlined,CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var bizuserid= ''

var token=''


class PurchaseReciptViewEdit extends React.Component {

  state = {
    receiptItem:{},
    receiptitems:[],
    item:'',
    products:[],
    date:moment().format(dateFormat).toString(),
    product:'',
    pricecategory:'',
    productobjec:{},
    amounttobepaid:0,
    stock:'',
    unit:'',
    size: 'medium',
    productname:"",
    cleintphone:"",
    clientname:"",
    receipttotal:0,
    receiptbalance:0,
    amountpaid:0,
    autoreceiptno:moment().format("DDMMYYYYHHmmss").toString(),
    manualreceiptno:'',
    itemnumber:0,
    receipt_number:'',
    receipttype:'notax',
    vatpercentage:0,
    netAmount:0,
    totalTax:0,

    ////////////////////////////////
    //payments form variables
    paymentmode:'',
    amount:'',
    comments:'',
    phonenumberfrom:'',
    phonenumberto:'',
    accountto:'',
    clientbank:'',
    transreference:'',
    chequeno:'',   
    viewpaymentmodal:false, 

    vatpercentage:0,
    assignedstores:[],
    storefrom:0,
    storefromquantity:0,


    clients:[],
    client:'',
    clientdetails:{},
    clientmodalvisible:false,

    itemupdate:false,
    selectedupdateitem:{},
    requestsent:true,

    editablesellingprice:false,
    userprofile:{},
    editedsellingprice:0,
    serviceproduct:false,
    datasubmittedsuccessfully:false,
    datarequested:true,
    companyprofile:{},

    station:'',
    stations:[],
    stationobj:{},


    team:'',
    teams:[],
  
    distributer:'',
    distributerobj:{},

    distributers:[],
    distributer_receiptno:0


  }


  componentDidMount(){

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }

    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

     axios.defaults.headers={
       "Content-Type":"application/json",
       Authorization:`Token ${token}`
     }


    axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })


  axios.get(`${serverconfig.backendserverurl}/api/accounts/${bizuserid}`)
  .then(res => {  
      this.setState({
        userprofile: res.data
      })

  })


  axios.get(serverconfig.backendserverurl+`/api/stations/`)
  .then(res => {
      this.setState({
        stations:res.data
      })

      this.setState({datarequested:false})
    })


  const purchaseID=this.props.match.params.purchaseID;

   //uget the receipt details
   axios.get(`${serverconfig.backendserverurl}/api/purchase_receipts/${purchaseID}/`)
   .then(res => {  
    this.setState({date:res.data.date})

    this.setState({team:res.data.team})
    this.setState({distributer:res.data.distributor})
    this.setState({station:res.data.station})
    this.setState({receiptitems:JSON.parse(res.data.all_items)})

    this.setState({distributer_receiptno:res.data.distributer_receiptno})



   //get distributers and teams
   axios.get(serverconfig.backendserverurl+`/api/stations/${res.data.station}/`)
   .then(res => {
       this.setState({
         stationobj:res.data
       })
   })


   //get teams and distributers
   axios.get(serverconfig.backendserverurl+`/api/distributers/?station=${res.data.station}`)
   .then(res => {
       this.setState({
         distributers:res.data
       })
   })

   axios.get(serverconfig.backendserverurl+`/api/teams/?station=${res.data.station}`)
   .then(res => {
       this.setState({
         teams:res.data
       })
   })


    //set dist items
    axios.get(serverconfig.backendserverurl+`/api/distributers/${res.data.distributor}/`)
    .then(res => {
        this.setState({
          distributerobj:res.data
        })
    })
  
    //get distrubuter products
    axios.get(serverconfig.backendserverurl+`/api/products/?distributer=${res.data.distributor}`)
    .then(res => {
        this.setState({
          products:res.data
        })
    })

   })

  }


  callback(key) {
    console.log(key);
  }


  /////////////////////////////////////////////
  // MODAL METHODS BEGIN HERE

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  //Item addition success
  handleOk = () => {
      if(this.state.quantity===0){
        message.info("The quantity cant be zero")
      }
      else{

        if (this.state.itemupdate===true){
          this.setState({requestsent:true})
  
          const elementsIndex = this.state.receiptitems.findIndex(element => element.key == this.state.selectedupdateitem.key )
    
          let newArray = [...this.state.receiptitems]

          newArray[elementsIndex] = {...newArray[elementsIndex], 
            item:this.state.productobjec.product_name,
            productid:this.state.productobjec.id,
            rate:this.state.editablesellingprice?this.state.editedsellingprice:this.state.productobjec.price,
            quantity:Number(this.state.quantity),
            amount:this.calculateamounttobepaid(),
            unit:this.state.productobjec.unitname,
          }
    
          this.setState({
          receiptitems: newArray,
          });
    
          this.setState({itemupdate:false})
          this.setState({requestsent:false})
          this.setState({ visible: false });

        }else{

          //increase item number
          this.setState({itemnumber:(Number(this.state.itemnumber)+1)})
          
          const newreceiptitem={
            key:uuid(),
            itemnumber:(Number(this.state.itemnumber)+1),
            item:this.state.productobjec.product_name,
            productid:this.state.productobjec.id,
            rate:this.state.editablesellingprice?this.state.editedsellingprice:this.state.productobjec.price,
            quantity:Number(this.state.quantity),
            amount:this.calculateamounttobepaid(),
            unit:this.state.productobjec.unitname,
          }

          //add to the receipt item list
          this.setState({
            receiptitems: [...this.state.receiptitems, newreceiptitem]
            });

            message.info(this.state.productname+' added to receipt');
            this.setState({ visible: false });
          
        }

      }

      this.setState({editablesellingprice:false})

  };

  handleCancel = () => {
    this.setState({ visible: false });
  };


  //create item total
  calculateamounttobepaid=()=>{
    var total=0;
    if (JSON.stringify(this.state.productobjec) ==="{}"){
      total=0
    }else{
    
    if (this.state.editablesellingprice===true){
      total=Number(this.state.editedsellingprice)*Number(this.state.quantity)

    }else{
      total=Number(this.state.productobjec.price)*Number(this.state.quantity)

    }
    
    }
    return total;


  }

  //calculate items total price
  calculateItemsTotalPrice=()=>{
    var total=0;
    this.state.receiptitems.map((item)=>{
      total+=Number(item.amount)
    })
    return total;
  }

  //calculate total commsison
  calculateTotalCommission=()=>{
    var commsn=0

    if(JSON.stringify(this.state.distributerobj)!='{}'){
      commsn=(Number(this.state.distributerobj.commission_percentage)/100)*this.calculateItemsTotalPrice()
    }

    return commsn;

  }

  //upline downline commission
  calculateTotalCommissionUpline=()=>{
    var commsn=0

    if(JSON.stringify(this.state.companyprofile)!='{}'){
      commsn=(Number(this.state.companyprofile.upline_percentagecommission_onsale)/100)*this.calculateTotalCommission()
    }

    return commsn;

  }

    //upline downline commission
    calculateTotalCommissionTeam=()=>{
      var commsn=0
  
      if(JSON.stringify(this.state.companyprofile)!='{}'){
        commsn=(Number(this.state.companyprofile.team_percentagecommission_onsale)/100)*this.calculateTotalCommission()
      }
  
      return commsn;
  
    }


  //////////////////////////////////////////
  // OTHER RECEIPT OPERATIONS
  clearReceipt=()=>{
    this.setState({receiptitems:[]})
  }

  removeItem=(id)=>{
    console.log(id)
    this.setState({ receiptitems:  [...this.state.receiptitems.filter(todo => todo.key
      !==id)]});
  }


  /////////////////////////////////////////////
  //////////////////////////////////////
  // MAKE FINAL RECEIPT SUBMISSION
  submitSale=()=>{
      const purchaseID=this.props.match.params.purchaseID;


      if(this.state.receiptitems.length>0 && this.calculateItemsTotalPrice()>0){
        this.setState({datarequested:true})

        let form_data = new FormData();
        form_data.append('date', this.state.date);
        form_data.append('distributor', this.state.distributer);
        form_data.append('station', this.state.station);
        form_data.append('team', this.state.team);

        form_data.append('totalamount', this.calculateItemsTotalPrice());
        form_data.append('user', localStorage.getItem('username'));
        form_data.append('all_items', JSON.stringify(this.state.receiptitems));

        form_data.append('total_commission', this.calculateTotalCommission())
        form_data.append('dividend_total', this.calculateTotalCommission()-(this.calculateTotalCommissionTeam()+this.calculateTotalCommissionUpline()))
        form_data.append('total_commission_out', this.calculateTotalCommissionTeam()+this.calculateTotalCommissionUpline())
  
        form_data.append('total_commission_out_team', this.calculateTotalCommissionTeam())
        form_data.append('total_commission_out_upline', this.calculateTotalCommissionUpline())
        form_data.append('distributer_receiptno', this.state.distributer_receiptno)

        //Now submit sale data to database
          axios.put(serverconfig.backendserverurl+`/api/purchase_receipts/${purchaseID}/`, form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
        .then(res =>{
          this.setState({datarequested:false})
          
          this.setState({datasubmittedsuccessfully:true})
          

        })
        .catch(error => console.log(error))
      }else{
        message.info("Please Add items on the list")
      }

  }


  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    const columns = [
      {
        title: '#',
        dataIndex: 'itemnumber',
        key: 'key',
      }, 
      {
        title: 'ITEM',
        dataIndex: 'item',
        key: 'item',
      },
      {
        title: 'QUANTITY',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'UNIT',
        dataIndex: 'unit',
        key: 'key',
      },
      {
        title: 'RATE',
        dataIndex: 'rate',
        key: 'rate',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}   />
      },
      {
        title: 'AMOUNT',
        dataIndex: 'amount',
        key: 'amount',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'ACTION',
        dataIndex: 'key',
        key: 'key',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit item</p>} title="Edit Item">
        <EditOutlined style={{color:'blue'}} onClick={(event) =>{

            this.setState({itemupdate:true})
            this.setState({requestsent:true})
            this.setState({editablesellingprice:false})

              this.state.receiptitems.map(
                (item)=>{
                  if(item.key==text){
                    this.setState({quantity:item.quantity});

                    axios.get(`${serverconfig.backendserverurl}/api/products/${item.productid}/`)
                    .then(res => {
                        this.setState({
                          productobjec:res.data
                        })

                        this.setState({unit:res.data.unitname})
                        this.setState({productname:res.data.product_name})
                        this.setState({ product: res.data.product});
                    })

                  }
                })

                this.setState({selectedupdateitem:record})
                this.setState({requestsent:false})
                this.setState({visible:true})

        }} />
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete item</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={(event) => this.removeItem(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>
      },

    ];


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
         
            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successful Purchase update"
                    extra={[
                     
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>
                    ]}
                    />
  
                    </Card>
  
                    </Col>
            </Row>
  
          </div>
  
        )
  
      }else{
        return(
          <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="PURCHASE ENTRY" key="1">

            <Form  >
            <FormItem label={"Date ( "+this.state.date+" )"}>

                <DatePicker  onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
            </FormItem>

            <FormItem label="Station">
            <Select placeholder="Station" 
            value={this.state.station} 
            onChange={(val)=>{
              this.setState({station:val})

              axios.get(serverconfig.backendserverurl+`/api/stations/${val}/`)
              .then(res => {
                  this.setState({
                    stationobj:res.data
                  })
              })


              //get teams and distributers
              axios.get(serverconfig.backendserverurl+`/api/distributers/?station=${val}`)
              .then(res => {
                  this.setState({
                    distributers:res.data
                  })
              })

              axios.get(serverconfig.backendserverurl+`/api/teams/?station=${val}`)
              .then(res => {
                  this.setState({
                    teams:res.data
                  })
              })


            }} 
            showSearch
            optionFilterProp="children"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onSearch={this.onSearch}
            >
                {this.state.stations.map(
                  (item)=>(
                    <Option value={item.id}>{item.station_name}</Option>
                  ))}
            </Select>  

            </FormItem>

          <FormItem label="Distributor">
           <Select placeholder="Distributor" 
           value={this.state.distributer} onChange={(val)=>{
            
            this.setState({distributer:val})

            axios.get(serverconfig.backendserverurl+`/api/distributers/${val}/`)
            .then(res => {
                this.setState({
                  distributerobj:res.data
                })
            })
          
            //get distrubuter products
            axios.get(serverconfig.backendserverurl+`/api/products/?distributer=${val}`)
            .then(res => {
                this.setState({
                  products:res.data
                })
            })

          
          }} 
           showSearch
           optionFilterProp="children"
           onFocus={this.onFocus}
           onBlur={this.onBlur}
           onSearch={this.onSearch}    
           >
             {this.state.distributers.map(
               (dst)=>(
                 <Option value={dst.id}>{dst.name}</Option>
               ))}
           </Select>
            </FormItem>

            <FormItem label="Distributor receipt no">
                  <Input name="distributer_receiptno" type="number"  placeholder="Distributor receipt no" value={this.state.distributer_receiptno} onChange={(val)=>{this.setState({distributer_receiptno:val.target.value})}}/>
            </FormItem> 


            <FormItem label="Team">
              <Select 
              placeholder="Team" 
              style={{  }} 
              value={this.state.team}
              onChange={(val)=>{
                this.setState({team:val})
                //set details 
              }} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}                   
              >
                {this.state.teams.map(
                  (accnt)=>(
                    <Option value={accnt.id}> {accnt.team_name} | {accnt.tel_contact}</Option>
                  ))}
              </Select>
          </FormItem>

          </Form>               
  
  
             <br></br>
             <div >
             <Table columns={columns} 
             dataSource={this.state.receiptitems}
             scroll={{ x: 1000 }}
             bordered/>
             </div>
  
              <br></br>
              <div style={{overflowX:'auto'}} >
                  <reactstrp.Table>
                      <tbody>
                      <tr>
                      
                        <td>
                        <div>
                          <h2>Total: <CurrencyFormat style={{color:'green'}} value={this.calculateItemsTotalPrice()} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></h2>
                        </div>
                        </td> 

                        <td>
                        <div>
                          <h4>Total commission ( {this.state.distributerobj.commission_percentage} %  from distributor): <CurrencyFormat style={{color:'green'}} value={this.calculateTotalCommission()} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></h4>
                          <h4>Total commission out for team  ({this.state.companyprofile.team_percentagecommission_onsale} % ): <CurrencyFormat style={{color:'green'}} value={this.calculateTotalCommissionTeam()} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></h4>
                          <h4>Total commission out for upline  ({this.state.companyprofile.upline_percentagecommission_onsale} % ): <CurrencyFormat style={{color:'green'}} value={this.calculateTotalCommissionUpline()} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></h4>
                          <h4>Total commission out : <CurrencyFormat style={{color:'green'}} value={this.calculateTotalCommissionUpline()+this.calculateTotalCommissionTeam()} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></h4>

                          <h4>Total dividend : <CurrencyFormat style={{color:'green'}} value={this.calculateTotalCommission()-(this.calculateTotalCommissionTeam()+this.calculateTotalCommissionUpline())} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></h4>

                        </div>
                        </td> 
                                            
                      </tr>
                    
                    </tbody>
                  </reactstrp.Table>  
                </div>
  
              <br></br>
  
              <div style={{overflowX:'auto'}} >
              <reactstrp.Table>
                      <tbody>
                      <tr>
                      <td>
                        <div>
                          <Tooltip title="Click to add Item to List" placement="top">
                          <p ><PlusSquareFilled style={{ fontSize: '40px', color: '#08c' }} onClick={this.showModal}/></p>
                          <h6 >Add Item</h6>
                          </Tooltip>
                        </div>
                        </td>
                        <td>
                        <div >
                          <Tooltip title="Click to clear List" placement="top">
                          <p ><CloseCircleFilled style={{ fontSize: '40px', color: '#08c' }} onClick={this.clearReceipt}/></p>
                          <h6 >Clear List</h6>
                          </Tooltip>
                        </div>
                        </td>

                        <td>
                        <div>
                          <Tooltip title="Click to save sale" placement="top">
                          <p ><SaveOutlined style={{ fontSize: '40px', color: '#08c' }} onClick={this.submitSale}/></p>
                          <h6 >Update</h6>
                          </Tooltip>
                        </div>
                        </td>
                    
                      </tr>
                    
                    </tbody>
                  </reactstrp.Table>  
                </div>
  
                  <Modal
                    visible={this.state.visible}
                    title={this.state.itemupdate?"Item Update":" Item Addition"} 
                  
                    width={600}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                      <Button key="back" onClick={this.handleCancel}>
                        Cancel
                      </Button>,
                      this.state.itemupdate?
                      <Button key="submit" type="primary"  onClick={this.handleOk}>
                        Update
                      </Button>
                      :
                      <Button key="submit" type="primary"  onClick={this.handleOk}>
                        Add
                      </Button>                    
                      ,
                      ]}
                      >
  
                       {
                        this.state.itemupdate?
                        null
                        :
                        <FormItem label="Select Item (Product)">
                            <Select 
                            showSearch
                            optionFilterProp="children"
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onSearch={this.onSearch}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Item"  
                            value={this.state.product} 
                            onChange={(val)=>{
                              //set product
                              this.setState({product:val})

                              axios.get(`${serverconfig.backendserverurl}/api/products/${val}`)
                              .then(res => {
                                  this.setState({
                                    productobjec:res.data
                                  })
                                })

                            }} >
                              {this.state.products.map(
                                (prdt)=>(
                                  <Option value={prdt.id}>{prdt.product_name}</Option>
                                ))}
                            </Select>
                        </FormItem>
                       }
  
                        <FormItem label="Quantity">
                              <Input name="quantity" type="number"  placeholder="Quantity" value={this.state.quantity} onChange={(e) => this.setState({ quantity: e.target.value})}/>
                        </FormItem> 
  
  
                      {
                        this.state.userprofile.managerial_account?
                        <div style={{display:'flex',flexDirection:'row'}}>
                          <h5>Edit Seeling Price?</h5>
                        <Switch
                        checked={this.state.editablesellingprice}
                        onChange={val=>{
                          this.setState({editablesellingprice:!this.state.editablesellingprice})
                        }}
                        checkedChildren="YES"
                        unCheckedChildren="NO"
                        />
                        </div>
                        :
                        null
                      }
  
                      {
                        this.state.editablesellingprice===true?
                          <FormItem label="Purchase Price">
                                <Input name="sellingprice" type="number"  placeholder="Purchase price" value={this.state.editedsellingprice} onChange={(val)=>{this.setState({editedsellingprice:val.target.value})}}/>
                          </FormItem> 
                        :
                        null
                      }
  
  
                        {
                        this.state.itemupdate?
                        null
                        :
                        <h5>RATE: { this.state.editablesellingprice?this.state.editedsellingprice:this.state.productobjec.price}</h5>
                        }
  
                        {
                        this.state.itemupdate?
                        null
                        :
                        <h5>TOTAL: {this.calculateamounttobepaid()}</h5>
                        }
  
                    </Modal>
  
              </Panel>
  
            </Collapse>
  
      </div>)
  
      }

    }

  }
}

export default PurchaseReciptViewEdit; 

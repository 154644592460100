import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Modal,Form, Input,Select,Spin,Image, message } from 'antd';
import AccountUpdateForm from '../components/AccountUpdateForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { Drawer,Divider } from 'antd';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import PhoneInput from 'react-phone-number-input'

const FormItem=Form.Item;
const { Option } = Select;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class CompanyProfile extends React.Component{

    state={
        profile: {},
        datarequested:true,
        company_name:'',
        company_logo:null,
        company_logo_file:'',
        company_email:'',
        RegisteredPhoneNumber1:'',
        RegisteredPhoneNumber2:'',

        box_number:'',
        address:'',
        downline_limit:0,
        no_oftransactionaldays_tobeactive:0,
        team_percentagecommission_onsale:0,
        upline_percentagecommission_onsale:0,

        companyaccounts: [],
        selected_account:'',
        stationCommission_account:'',
        teamCommission_account:''
    
    }


    componentDidMount(){
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

        axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
        .then(res => {  
            this.setState({
              profile: res.data
            })

            this.setState({company_name:res.data.company_name})
            this.setState({company_email:res.data.company_email})
            this.setState({RegisteredPhoneNumber1:res.data.RegisteredPhoneNumber1})
            this.setState({RegisteredPhoneNumber2:res.data.RegisteredPhoneNumber2})

            this.setState({box_number:res.data.box_number})
            this.setState({address:res.data.address})
            this.setState({downline_limit:res.data.downline_limit})

            this.setState({no_oftransactionaldays_tobeactive:res.data.no_oftransactionaldays_tobeactive})
            this.setState({team_percentagecommission_onsale:res.data.team_percentagecommission_onsale})
            this.setState({upline_percentagecommission_onsale:res.data.upline_percentagecommission_onsale})

            this.setState({company_logo_file:res.data.company_logo})

            this.setState({stationCommission_account:res.data.stationCommission_account})
            this.setState({teamCommission_account:res.data.teamCommission_account})


            this.setState({datarequested:false})
        })


        axios.get(serverconfig.backendserverurl+`/api/companyaccounts/`)
        .then(res => {
            this.setState({
                companyaccounts:res.data
            })
        })
    }

    handleFormSubmit=(event) =>{
      this.setState({datarequested:true})
    
      let form_data = new FormData();
  
      this.state.company_logo==null?
      console.log("No company_logo file")
      :
      form_data.append('company_logo', this.state.company_logo,this.state.company_logo?this.state.company_logo.name:"");
  
      form_data.append('company_name', this.state.company_name);
      form_data.append('company_email', this.state.company_email);
      form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
      form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);
      form_data.append('box_number', this.state.box_number);
      form_data.append('address', this.state.address);

      form_data.append('downline_limit', this.state.downline_limit);
      form_data.append('no_oftransactionaldays_tobeactive', this.state.no_oftransactionaldays_tobeactive);
      form_data.append('team_percentagecommission_onsale', this.state.team_percentagecommission_onsale);
      form_data.append('upline_percentagecommission_onsale', this.state.upline_percentagecommission_onsale);
  
      form_data.append('stationCommission_account', this.state.stationCommission_account);
      form_data.append('teamCommission_account', this.state.teamCommission_account);

      if(this.state.stationCommission_account===this.state.teamCommission_account){
        message.error("Team commission account cant be the same as station commission account")

      }else{

        axios.put(`${serverconfig.backendserverurl}/api/companyprofile/${1}/`,form_data, {
          headers: {
          'content-type': 'multipart/form-data'
          }
      })
      .then(res =>this.setState({datarequested:false}), window.location.reload(false))
      .catch(error => console.log(error))
      }



      }


    render(){

      if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>            </div>
        )
  
      }else{
        return(
          <div>
             
              <Card 
                style={{padding:10}}
                hoverable>
              <Form
                    onFinish={(event) => this.handleFormSubmit(event)}
                    >
                        <FormItem label="Company Name">
                            <Input name="company_name"  placeholder="Company name." value={this.state.company_name} onChange={(val)=>{this.setState({company_name:val.target.value})}} />
                        </FormItem>

                        <div style={{display:'flex',flexDirection:'row'}}>

                        <FormItem label="Company logo">
                            <Input name="companylogo" type="file" accept="image/png, image/jpeg" placeholder="Company logo" 
                            
                            onChange={(e) =>{
                            if(e.target.files[0]){
                              this.setState({
                                company_logo_file: URL.createObjectURL(e.target.files[0])
                              })
                            }
                            this.setState({ company_logo: e.target.files[0]})

                            } }/>
                        </FormItem> 

                        {this.state.company_logo!=null || this.state.company_logo_file!=""?
                          <Image
                          width={50}
                          height={50}
                          src={this.state.company_logo_file}    
                          />
                          :
                          null
                          }


                        </div>

                        <FormItem label="Company Email">
                            <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.company_email} onChange={(val)=>{this.setState({company_email:val.target.value})}} />
                        </FormItem> 
 
        
                        <FormItem label="Phone Number one">
                        <PhoneInput
                                countrySelectProps={{ unicodeFlags: true }}
                                defaultCountry="UG"
                                placeholder="Enter phone number"
                                value={this.state.RegisteredPhoneNumber1} onChange={(val)=>{this.setState({RegisteredPhoneNumber1:val})}}/>
                        </FormItem> 


                        <FormItem label="Phone Number two">
                        <PhoneInput
                                countrySelectProps={{ unicodeFlags: true }}
                                defaultCountry="UG"
                                placeholder="Enter phone number"
                                value={this.state.RegisteredPhoneNumber2} onChange={(val)=>{this.setState({RegisteredPhoneNumber2:val})}}/>
                        </FormItem>
                        
        
                        <FormItem label="Box number">
                            <Input placeholder="Box number here" value={this.state.box_number} onChange={(val)=>{this.setState({box_number:val.target.value})}} />
                        </FormItem>

        
                        <FormItem label="Address">
                            <Input  placeholder="Address" value={this.state.address} onChange={(val)=>{this.setState({address:val.target.value})}} />
                        </FormItem>


                        <FormItem label="Team downline limit">
                            <Input  type="number"  placeholder="Downline limit" value={this.state.downline_limit} onChange={(val)=>{this.setState({downline_limit:val.target.value})}}/>
                        </FormItem>


                        <FormItem label="Number of transactions days to be inactive">
                            <Input  type="number"  placeholder="Transactions no" value={this.state.no_oftransactionaldays_tobeactive} onChange={(val)=>{this.setState({no_oftransactionaldays_tobeactive:val.target.value})}}/>
                        </FormItem>

                        <FormItem label="Team percentage commission on purchase">
                            <Input  type="number"  placeholder="Percentage" value={this.state.team_percentagecommission_onsale} onChange={(val)=>{this.setState({team_percentagecommission_onsale:val.target.value})}}/>
                        </FormItem>

                        <FormItem label="Upline percentage commission on purchase">
                            <Input  type="number"  placeholder="Percentage" value={this.state.upline_percentagecommission_onsale} onChange={(val)=>{this.setState({upline_percentagecommission_onsale:val.target.value})}}/>
                        </FormItem>

                        <FormItem label={"Station dividends account"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.stationCommission_account}
                          onChange={(val)=>{this.setState({stationCommission_account:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code} </Option>
                              ))}
                          </Select>
                      </FormItem>


                      <FormItem label={"Team commission account"}>
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.teamCommission_account}
                          onChange={(val)=>{this.setState({teamCommission_account:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>
        
                        <FormItem>
                            <Button type="primary" htmlType="submit" >Update Profile</Button>
                        </FormItem>
                        </Form>
                      
              </Card>
             
          </div>
      )


      }
       
    }
}

export default CompanyProfile; 
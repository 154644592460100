export const backendserverurl='https://0001data-bkd.oraclevantagepoint.com'

//https://1-be-pifs01-mta-app1.pitech.co.ug
//127.0.0.1:8000
//138.197.8.179
//http://138.197.8.179:10225/
//1-be-getnetdataaa.ge-network.org
//2-be-med-daaaaaaatataaa02.ug-usmedconsult.com


// https://0001data-bkd.oraclevantagepoint.com/

//http://198.211.98.21:7879


import React from 'react';
import { Form, Input, Button,Select,Checkbox,Upload,message,Spin,Image } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import Resizer from "react-image-file-resizer";

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class UserAccountUpdateForm extends React.Component {

  state = {
    profile_pic:null,   
    profile_pic_ur:'',   

    email:'',
    username:'',
    is_admin:false,
    is_active:false,
    is_staff:false,
    is_superuser:false,
    managerial_account:false,

    datarequested:true,

  }

  componentDidMount(){
    var accountID= this.props.accountID

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accounts/${accountID}`)
    .then(res => {
        this.setState({email:res.data.email})
        this.setState({username:res.data.username})
        this.setState({is_admin:res.data.is_admin})
        this.setState({is_active:res.data.is_active})
        this.setState({is_staff:res.data.is_staff})
        this.setState({is_superuser:res.data.is_superuser})
        this.setState({managerial_account:res.data.managerial_account})

        this.setState({datarequested:false})
    })



  }


  //submit button pressed
  onFinish=values =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('username', this.state.username);
    form_data.append('email', this.state.email);
    form_data.append('managerial_account', this.state.managerial_account);
    form_data.append('is_admin', this.state.is_admin);
    form_data.append('is_active', this.state.is_active);
    form_data.append('is_staff', this.state.is_staff);
    form_data.append('is_superuser', this.state.is_superuser);

    this.state.profile_pic==null?
    console.log("No profile file")
    :
    form_data.append('profile_pic', this.state.profile_pic, this.state.profile_pic?this.state.profile_pic.name:"");


    //Now send the post resquest
      axios.put(`${serverconfig.backendserverurl}/api/accounts/${this.props.accountID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res =>{
      this.setState({datarequested:false})
      message.info('User Profile Updated successfully');

      //localStorage.setItem('username',values.username);
      window.location.reload(false);
    } 
    )
    .catch(error => console.log(error)) 
    };


//imgae resizer
resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    300,
    400,
    "JPEG",
    50,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

//convert back to file
 dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};


    handleprofilepicChange= async(e) =>{
      if(e.target.files[0]){
        this.setState({
          profile_pic_ur: URL.createObjectURL(e.target.files[0])
        })
      }
  
      const file = e.target.files[0];
      //send to resizer
      const image = await this.resizeFile(file);
      const newFile = this.dataURIToBlob(image);
      this.setState({ profile_pic: newFile});
      console.log(newFile);

    } 


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
          <Form  
          onFinish={this.onFinish}  >
  
            <FormItem 
            >
              <Input 
              prefix={<UserOutlined className="site-form-item-icon" />} 
              placeholder="Put a name here." 
              onChange={(val)=>{this.setState({username:val.target.value})}}
              value={this.state.username} />
            </FormItem>
  
            <FormItem 
            >
              <Input  type="mail" prefix={<MailOutlined className="site-form-item-icon" />}  
              placeholder="Put a name here."
               value={this.state.email} 
               onChange={(val)=>{this.setState({email:val.target.value})}}
               />
            </FormItem>
  
            <div style={{display:'flex',flexDirection:'row'}}>

            <FormItem label="Profile Image">
              <Input  type="file" 
              accept="image/png, image/jpeg"
              placeholder="Profile Image" 
              onChange={this.handleprofilepicChange}/>

            {this.state.profile_pic?
              <Image
              width={50}
              height={50}
              src={this.state.profile_pic_ur}    
              />
              :
              null
              }

            </FormItem>

            </div>

            <FormItem>
              <Button onClick={()=>{this.onFinish()}} type="primary">Update</Button>
            </FormItem>
          </Form>
  
        </div>
        
      );

    }

  }

}

export default UserAccountUpdateForm;




import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Table,Popover,Popconfirm,message,Modal,Switch } from 'antd';
import { Form, Input,Select } from 'antd';

import {
    Col,
    Row,
  } from 'reactstrap';


import { UserOutlined,FundViewOutlined,DeleteOutlined,EditOutlined } from '@ant-design/icons';
import QRCode from 'qrcode.react';
import * as serverconfig from '../serverconn'
import { Link } from 'react-router-dom';

const FormItem=Form.Item;
const { Option } = Select;

class ProductDetail extends React.Component{
    canvasRef = React.createRef();

    state={
        product: {},
        serial:'',
        productName:'' ,  
        unit:'',
        price:0,
        unit_buying_price:0,

        productDescription:'',
        productSerial:'',
        productImage:null,   
        primaryunits:'',
        productunits:[],
        ingredients:[],
        productID:'',
        dependantproduct:false,
        selectedingredient:{},
        showingredienteditmodal:false,
        ingredientquantity:0,
        subproducts:[],
        stockunits:[],
        hassubproducts:false,

        warningstocklevel:0,
        alertstocklevel:0,
        service_product:false,
        distributor:'',
        distributers:[]


    }

    componentDidMount(){
        const productID=this.props.match.params.productID;
        this.setState({productID:productID})

        axios.get(`${serverconfig.backendserverurl}/api/products/${productID}`)
        .then(res => {  
            this.setState({
                product: res.data
            })

            this.setState({productName:res.data.product_name})
            this.setState({unit:res.data.unit})
            this.setState({price:res.data.price})
            this.setState({distributor:res.data.distributer})

        })

        axios.get(serverconfig.backendserverurl+`/api/productunits/`)
        .then(res => {
            this.setState({
              productunits:res.data
            })
        })


        axios.get(serverconfig.backendserverurl+`/api/distributers/`)
        .then(res => {
            this.setState({
              distributers:res.data
            })    
        })

    }


//submit button pressed
handleFormSubmit=(event) =>{
    const productID=this.props.match.params.productID;

    let form_data = new FormData();

    form_data.append('product_name', this.state.productName);
    form_data.append('unit', this.state.unit);
    form_data.append('price', this.state.price);
    form_data.append('distributer', this.state.distributor);

    axios.put(serverconfig.backendserverurl+`/api/products/${productID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => window.location.reload(false)
  )
  .catch(error => console.log(error))

  }

  handleProductNameChange= (e) => this.setState({ productName: e.target.value});
  handleunitChange= (e) => this.setState({ unit: e});
  handleunitpriceChange= (e) => this.setState({ unitprice: e.target.value});
  handleProductDescriptionChange= (e) => this.setState({ productDescription: e.target.value});
  handleProductSerialChange= (e) => this.setState({ productSerial: e.target.value});
  handleProductImageChange= (e) => this.setState({ productImage: e.target.files[0]});
  handleprimaryunitsChange= (e) => this.setState({ primaryunits: e.target.value});

  handlewarningstocklevelChange= (e) => this.setState({ warningstocklevel: e.target.value});
  handlealertstocklevelChange= (e) => this.setState({ alertstocklevel: e.target.value});

  handlepriceChange= (e) => this.setState({ price: e.target.value});

  handlehassubproductsChange=(value)=>{
    //alert(this.state.usesms)
    this.setState({hassubproducts:!this.state.hassubproducts})
  }

  handledependancyChange=(value)=>{
    //alert(this.state.usesms)
    this.setState({dependantproduct:!this.state.dependantproduct})
  }

    downloadQR = () => {
    const canvas = document.getElementById("123456");
    const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrcode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    };

             //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/productingredients/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };

               //////////////////////////////////////////////////
  //handle delete here
  handleDeletesubproduct = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/subproducts/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };

  handleDeletestockunit = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/productstockunit/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };


      handleingredientquantityChange= (e) =>{
        this.setState({ingredientquantity:e.target.value})
      }



    render(){

        return(
              <div>
               
                <Card title="Product Update">

                <Form
                        onFinish={(event) => this.handleFormSubmit(event)}
                >
                    <FormItem label="Product Name">
                    <Input name="productname"  placeholder="Put a name here." value={this.state.productName} onChange={this.handleProductNameChange} />
                    </FormItem>

                    <FormItem label="Product Unit">
                            <Select placeholder="Unit" style={{ width: 120 }} value={this.state.unit} onChange={this.handleunitChange} >
                                {this.state.productunits.map(
                                (unt)=>(
                                    <Option value={unt.id}>{unt.unitname}</Option>
                                ))}
                            </Select>
                    </FormItem>


                    <FormItem label="Price">
                            <Input name="price" type="number"  placeholder="Unit Price" value={this.state.price} onChange={this.handlepriceChange}/>
                    </FormItem>

                <FormItem label="Distributor">
                    <Select placeholder="Distributor" 
                    value={this.state.distributor} 
                    onChange={(val)=>{this.setState({distributor:val})}} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}    
                    >
                      {this.state.distributers.map(
                        (dst)=>(
                          <Option value={dst.id}>{dst.name}</Option>
                        ))}
                    </Select>
                </FormItem>


                <FormItem>
                <Button  type="primary" htmlType="submit">Update</Button>
                </FormItem>
                </Form>


                </Card>  
               
                

            </div>
        )

    }

}

export default ProductDetail; 
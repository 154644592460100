import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Input,message,Spin} from 'antd';
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
import {
    Col,
    Row,
  } from 'reactstrap';
const FormItem=Form.Item;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class UserroleDetail extends React.Component{

    state={
        speciality: {},
        role:'' ,  
        description:'',
        datarequested:true,

    }

    componentDidMount(){
        if(localStorage.getItem("token")){
          token= localStorage.getItem("token")
        }else{
          token= ''
        }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

        const roleID=this.props.match.params.roleID;
        axios.get(`${serverconfig.backendserverurl}/api/userroles/${roleID}`)
        .then(res => {  
            this.setState({
                speciality: res.data

            })

            this.setState({description:res.data.description})
            this.setState({role:res.data.role})
            this.setState({datarequested:false})
        })
    }



    //submit button pressed
  handleFormSubmit=() =>{
    this.setState({datarequested:true})

    const roleID=this.props.match.params.roleID;

    let form_data = new FormData();
    form_data.append('role', this.state.role);
    form_data.append('description', this.state.description);

    axios.put(`${serverconfig.backendserverurl}/api/userroles/${roleID}/`,form_data, {
        headers: {
        'content-type': 'multipart/form-data'
        }
    })
    .then(res =>this.setState({datarequested:false}),window.location.reload(false),message.info('Successfully updated'))
    .catch(error => console.log(error))

  }

    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>                </div>
            )
      
          }else{
            return(
                <div>
                    
                    <Card 
                    style={{padding:10}}
                    hoverable>
                        <h4>User role Update</h4>
                        <Form>
                            <FormItem label="Role">
                            <Input name="role"  placeholder="Put role here." value={this.state.role} onChange={(val)=>{this.setState({role:val.target.value})}} />
                            </FormItem>
                            <FormItem label="Description">
                            <Input name="description"  placeholder="Description here" value={this.state.description} onChange={(val)=>{this.setState({description:val.target.value})}} />
                            </FormItem>
                        
                            <FormItem>
                            <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                            </FormItem>
                        </Form>
    
                  </Card>
  
  
                  <br/>
              </div>
            )

          }
    }
}

export default UserroleDetail; 
import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Popconfirm,Popover,
  Collapse,Avatar,Drawer,Descriptions,message,Modal,Spin,Switch} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,FundOutlined,FundViewOutlined,DeleteOutlined,LockFilled,LockOutlined,LoadingOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import StationForm from '../components/StationForm'
import CurrencyFormat from 'react-currency-format';

var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const { Panel } = Collapse;
const FormItem=Form.Item;

const { Option } = Select;

function callback(key) {
  console.log(key);
}

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var sacco= ''
var username=''
var token= ''
var ngoid= ''

class StationList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    stations: [],
    rightsmodalvisible:false,
    rights: {},
    selectedid:'',
    datarequested:true,

    can_view_dashboard:true,
    can_enter_doctor:true,
    can_view_doctors:true,

    can_enter_speciality:true,
    can_view_speciality:true,

    can_enter_patient:true,
    can_view_patients:true,

    can_enter_newcase:true,
    can_edit_case:true,
    can_view_allcases:true,

    can_create_patient_reviews:true,
    can_edit_patient_reviews:true,
    can_view_patient_reviews:true,
    
    can_assign_cases:true,
    can_create_case_review:true,
    can_view_case_reviews:true,

    showupdate_model:false,
    station_name:'' ,  
    district:'',
    address:'',
    manager:'',
    station_id:''

   
  };

  displaypasswordupdatemodal=(id)=>{
    this.setState({showpasswordupdatemodal:true})
    this.setState({selectedid:id})
  }
  
  closepasswordupdatemodal=()=>{
    this.setState({showpasswordupdatemodal:false})
  }
  
  //////////////////////////////////////
  ///// change password here
  handlePasswordChange=(values)=>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('password1', values.new_password1);
    form_data.append('password2', values.new_password2);
    form_data.append('userid', this.state.selectedid);
  
    //Now send the post resquest
      axios.post(serverconfig.backendserverurl+'/customqueries/updatecompanyuserpassword', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
      this.setState({datarequested:false})

      message.info(res.data.message);
    }
    )
    .catch(error => console.log(error)) 
  
  }




  ///////////////////////////////////////////
  //getting simple user account rights
  showAccountRights=(text) =>{
    this.setState({ rightsmodalvisible: true });

    //make api call to get the user rights
    axios.get(`${serverconfig.backendserverurl}/api/accountrights?user=${text}`)
    .then(res => {  
        this.setState({
            rights: res.data[0]
        })
        this.setState({can_view_dashboard:res.data[0].can_view_dashboard})
        this.setState({can_enter_doctor:res.data[0].can_enter_doctor})
        this.setState({can_view_doctors:res.data[0].can_view_doctors})

        this.setState({can_enter_speciality:res.data[0].can_enter_speciality})
        this.setState({can_view_speciality:res.data[0].can_view_speciality})

        this.setState({can_enter_patient:res.data[0].can_enter_patient})
        this.setState({can_view_patients:res.data[0].can_view_patients})

        this.setState({can_enter_newcase:res.data[0].can_enter_newcase})
        this.setState({can_edit_case:res.data[0].can_edit_case})
        this.setState({can_view_allcases:res.data[0].can_view_allcases})

        this.setState({can_create_patient_reviews:res.data[0].can_create_patient_reviews})
        this.setState({can_edit_patient_reviews:res.data[0].can_edit_patient_reviews})
        this.setState({can_view_patient_reviews:res.data[0].can_view_patient_reviews})

        this.setState({can_assign_cases:res.data[0].can_assign_cases})
        this.setState({can_create_case_review:res.data[0].can_create_case_review})
        this.setState({can_view_case_reviews:res.data[0].can_view_case_reviews})

    })

  }

  //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/stations/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };


  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ rightsmodalvisible: false });
  };




  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('station_name', this.state.station_name);
    form_data.append('district', this.state.district);
    form_data.append('address', this.state.address);
    form_data.append('manager', this.state.manager);

      axios.put(serverconfig.backendserverurl+`/api/stations/${this.state.station_id}/`, form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
      ngoid= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/stations/`)
    .then(res => {
        this.setState({
          stations:res.data
        })

        //this.setState({datarequested:false})

    })

    axios.get(serverconfig.backendserverurl+`/api/accounts/`)
    .then(res => {
        this.setState({
          accounts:res.data
        })

        this.setState({datarequested:false})
    })

}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [
     
      {
        title: 'Station name',
        dataIndex: 'station_name',
        key: 'id',
        ...this.getColumnSearchProps('station_name'),
      },
      {
        title: 'District',
        dataIndex: 'district',
        key: 'id',
        ...this.getColumnSearchProps('district'),
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'id',
      },
      {
        title: 'Manager',
        dataIndex: 'manager_name',
        key: 'id',
      },
      {
        title: 'Account balance',
        dataIndex: 'account_balance',
        key: 'id',
        render:text=><h4 style={{color:'green'}}><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  /></h4>  
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <FundViewOutlined style={{color:'blue'}} onClick={()=>{
            this.setState({station_id:record.id})
            this.setState({manager:record.manager})
            this.setState({station_name:record.station_name})
            this.setState({address:record.address})
            this.setState({district:record.district})

            this.setState({showupdate_model:true})

         }}/>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete station</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      },

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>       
        </div>
      )

    }else{

      return(
        <div>
           <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL STATIONS" key="1">
            <Table 
             scroll={{ x: 1000 }}
             columns={columns}
             pagination={{showQuickJumper:true,showSizeChanger:true }}
             dataSource={this.state.stations} bordered/>
            </Panel>
            </Collapse>
            <br></br>
            <StationForm />
            <br></br>

            <Modal
                  visible={this.state.showupdate_model}
                  title="Station update form"
                  //width={200}
                  onCancel={(val)=>{this.setState({showupdate_model:false})}}  
                  footer={[
                    <Button key="back" onClick={(val)=>{this.setState({showupdate_model:false})}}>
                      Cancel
                    </Button>
                    ]}
                    >
                       <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Station name"
     
      >
        <Input name="station_name"  placeholder="Put a name here." value={this.state.station_name} onChange={(val)=>{this.setState({station_name:val.target.value})}} />
      </FormItem>
      
      <FormItem label="District"
        
      >
        <Input name="district"  placeholder="district here" value={this.state.district} onChange={(val)=>{this.setState({district:val.target.value})}} />
      </FormItem>

      <FormItem label="Address"
      
      >
        <Input name="address"  placeholder="address here" value={this.state.address} onChange={(val)=>{this.setState({address:val.target.value})}} />
      </FormItem>


      <FormItem label="Branch manager"
        
          >
              <Select 
              placeholder="Manager" 
              style={{  }} 
              value={this.state.manager}
              onChange={(val)=>{this.setState({manager:val})}} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}
                                  
              >
                {this.state.accounts.map(
                  (accnt)=>(
                    <Option value={accnt.id}> {accnt.username}</Option>
                  ))}
              </Select>
          </FormItem>
    

            <FormItem>
              <Button  type="primary" htmlType="submit">Update</Button>
            </FormItem>
          </Form>


               
                      
               </Modal>
        </div>
    )
    }
   
  }
}

export default StationList; 

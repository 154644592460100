import React from 'react';
import { Form, Input, Button,Spin,Select, message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input'

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;


var token= ''
var sacco= ''
var ngoid= ''


class DistributerForm extends React.Component {

  state = {
    name:'' ,  
    district:'',
    address:'',
    datarequested:false,
    accounts: [],
    station:'',
    stations: [],

    phone:'',
    email:'',
    account_no:'',
    commission_percentage:0

  }

  componentDidMount(){
    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accounts/`)
    .then(res => {
        this.setState({
          accounts:res.data
        })

        this.setState({datarequested:false})
    })

    axios.get(serverconfig.backendserverurl+`/api/stations/`)
    .then(res => {
        this.setState({
          stations:res.data
        })

        //this.setState({datarequested:false})

    })
  }


  //submit button pressed
  handleFormSubmit=(event) =>{

    if(this.state.phone===""){
      message.error("Phone number missing")
    }else{

      this.setState({datarequested:true})

      let form_data = new FormData();
      form_data.append('name', this.state.name);
      form_data.append('district', this.state.district);
      form_data.append('address', this.state.address);
      form_data.append('station', this.state.station);
  
      form_data.append('phone', this.state.phone);
      form_data.append('commission_percentage', this.state.commission_percentage);
      form_data.append('email', this.state.email);
      form_data.append('account_no', this.state.account_no);
  
        axios.post(serverconfig.backendserverurl+'/api/distributers/', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      .then(res =>     this.setState({datarequested:false})
  ,    window.location.reload(false)
      )
      .catch(error => console.log(error))

    }



  }

  handlespecilaityChange= (e) => this.setState({ name: e.target.value});
  handleIDdistrictChange= (e) => this.setState({ district: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>  
        </div>
      )

    }else{
      return (
        <div>
          <h4>Distributor form.</h4>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="name"  placeholder="Put a name here." value={this.state.name} onChange={(val)=>{this.setState({name:val.target.value})}} />
      </FormItem>
      
      <FormItem label="District"
        name="district"
        rules={[
          {
            required: true,
            message: 'Please input desc',
          },
        ]}    
      >
        <Input name="district"  placeholder="district here" value={this.state.district} onChange={(val)=>{this.setState({district:val.target.value})}} />
      </FormItem>

      <FormItem label="Address"
        name="address"
        rules={[
          {
            required: true,
            message: 'Please input desc',
          },
        ]}    
      >
        <Input name="address"  placeholder="address here" value={this.state.address} onChange={(val)=>{this.setState({address:val.target.value})}} />
      </FormItem>

      <FormItem label= { <h6 style={{display:'flex'}} >Phone number</h6>}                       
          >
          <PhoneInput
                countrySelectProps={{ unicodeFlags: true }}
                defaultCountry="UG"
                placeholder="Enter phone number"
                value={this.state.phone} onChange={(val)=>{this.setState({phone:val})}}/>
     </FormItem> 



      <FormItem label="Email Address"
          name="emailaddress"
          rules={[
            {
              required: true,
              message: 'Please input mail',
            },
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
          ]}            
      
      >
      <Input  type="mail" 
              prefix={<MailOutlined className="site-form-item-icon" />} 
              placeholder="Email"
              value={this.state.email} 
              onChange={(val)=>{this.setState({email:val.target.value})}} />
      </FormItem>

      <FormItem label="Account number"
            name="accountnumber"
            rules={[
              {
                required: true,
                message: 'Please enter account',
              },
            ]}           
        >
          <Input placeholder="Account number." value={this.state.account_no} onChange={(val)=>{this.setState({account_no:val.target.value})}} />
        </FormItem>

        <FormItem label="Commission percentage on sale"
            name="commissionpercentage"
            rules={[
              {
                required: true,
                message: 'Please enter percentage',
              },
            ]}           
        >
          <Input placeholder="Commission." value={this.state.commission_percentage} onChange={(val)=>{this.setState({commission_percentage:val.target.value})}} />
        </FormItem>


      <FormItem label="Station"
            name='station'
            rules={[
              {
                required: true,
                message: 'Please Select station',
              },
            ]}
          >
              <Select 
              placeholder="station" 
              style={{  }} 
              value={this.state.station}
              onChange={(val)=>{this.setState({station:val})}} 
              showSearch
              optionFilterProp="children"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onSearch={this.onSearch}
                                  
              >
                {this.state.stations.map(
                  (accnt)=>(
                    <Option value={accnt.id}> {accnt.station_name}</Option>
                  ))}
              </Select>
          </FormItem>
    

            <FormItem>
              <Button  type="primary" htmlType="submit">Create</Button>
            </FormItem>
          </Form>
        </div>
      );

    }
   
  }

}


export default DistributerForm;




import React from 'react';
import { Form, Input, Button,Spin } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''
var sacco= ''
var ngoid= ''


class UserRoleForm extends React.Component {

  state = {
    role:'' ,  
    description:'',
    IDvaliditydays:0,
    datarequested:false,
 
  }

  componentDidMount(){
    if(localStorage.getItem("ngoid")){
      ngoid= CryptoJS.AES.decrypt(localStorage.getItem("ngoid") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
      ngoid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('role', this.state.role);
    form_data.append('description', this.state.description);
      axios.post(serverconfig.backendserverurl+'/api/userroles/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>     this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

  }

  handlespecilaityChange= (e) => this.setState({ role: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ description: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>  
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="User role"
        name="role"
        rules={[
          {
            required: true,
            message: 'Please input role',
          },
        ]}
      >
        <Input name="role"  placeholder="Put a name here." value={this.state.role} onChange={(val)=>{this.setState({role:val.target.value})}} />
      </FormItem>
      
      <FormItem label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: 'Please input desc',
          },
        ]}    
      >
        <Input name="description"  placeholder="Description here" value={this.state.description} onChange={this.handleIDDescriptionChange} />
      </FormItem>
    

      <FormItem>
        <Button  type="primary" htmlType="submit">Create</Button>
      </FormItem>
    </Form>
        </div>
      );

    }
   
  }

}


export default UserRoleForm;



